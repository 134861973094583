import { useState, useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { SessionContext } from "../../context/SessionContext";
import { PreferenceContext } from "../../context/PreferenceContext";
import { ExperimentContext } from "../../context/ExperimentContext";
import { getSessionIdFromStorage } from "../../util/storage";
import { addSessionEventToDb } from "../../util/sessions";
import gaAnalytics from "../../util/ga";
import { redirectToDashboard } from "../../util/dashboard";
import { APP_NAME, TRIAL_DURATION } from "../../constants";
import { getPrices } from "../../util/prices";
import { getFormattedValue } from "../../util/money";
import Price from "../../components/Price";
import ChoicesField from "../../components/ChoicesField";
import { Row, Col } from "react-bootstrap";

const ChooseTrialStep = ({ baseUrl }) => {
  const [settings, setSettings] = useState({
    title: "Choose trial price",
    stepProgress: "Step 2 of 3",
    headline: "Choose a trial that’s right for you",
    subline:
      "Money should not stand in the way of finding a plan that finally works. So, choose an amount that you think is reasonable to make us your plans.",
    message:
      "*It costs us {price} to compensate our Foodimus employees for the trial, but please choose the amount you are comfortable with.",
    buttonText: "Continue",
    buttonTextNoPayment: "Continue for free",
    loadingText: "Loading..",
  });

  const history = useHistory();
  const { topicSlug } = useParams();
  const [session, setSession] = useContext(SessionContext);
  const [preferences] = useContext(PreferenceContext);
  const [experiment] = useContext(ExperimentContext);
  const { currency, locale } = preferences;
  const [prices, setPrices] = useState([]);
  const [priceOptions, setPriceOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleContinueButtonClick = async () => {
    const sessionId = getSessionIdFromStorage();
    await addSessionEventToDb(sessionId, "signupPriceChoiceButtonClick");
    continueNext();
  };

  const handlePriceOptionClick = async (name, value) => {
    setSession({ ...session, setupPrice: value });
    gaAnalytics.sendEvent({
      category: "Wizard",
      action: "Click",
      label: "Setup price choice",
      value,
    });
  };

  const continueNext = () => {
    if (session.paymentRequired) {
      history.push(`${baseUrl}/signup/payment`);
    } else {
      redirectToDashboard({
        name: session.name,
        email: session.email,
        topicSlug,
      });
    }
  };

  useEffect(() => {
    document.title = `${settings.title} - ${APP_NAME}`;
  }, [settings]);

  useEffect(() => {
    if (!experiment) return;
    if (!experiment.fv) return;
    switch (experiment.fv) {
      case 2:
        setSettings((prevState) => ({
          ...prevState,
          title: "Become part of a mission",
          stepProgress: false,
          headline: "Become part of a mission",
          subline:
            "Money should not stand in the way of finding a plan that finally works, but if you want to be part of our mission to make as much people eat healthier, help us with a one time contribution.",
          message:
            "* Monthly 10.000+ new people worldwide follow a FREE diet plan from Foodimus. Powered by contributions.",
          buttonText: "I would like to contribute",
          buttonTextNoPayment: "Continue without contributing",
        }));
        break;
      default:
    }
  }, [experiment]);

  useEffect(() => {
    if (!currency || !session.setupType) return;
    (async () => {
      const prices = await getPrices({
        currency,
        type: session.setupType,
      });
      setPrices(prices);
    })();
  }, [currency, session.setupType]);

  useEffect(() => {
    if (prices && prices.length > 0) {
      setPriceOptions(
        prices.map((obj) => ({
          id: obj.id,
          title: <Price value={obj.price} />,
          value: obj.id,
        }))
      );
      if (!session.setupPrice)
        setSession((prevState) => ({
          ...prevState,
          setupPrice: prices[0].id,
        }));
    }
  }, [prices, setSession]);

  return (
    <div>
      {(priceOptions.length === 0 && (
        <div className="text-center">{settings.loadingText}</div>
      )) || (
        <>
          <header className="mb-5 text-center">
            {settings.stepProgress && (
              <span className="text-uppercase d-block mb-2">
                {settings.stepProgress}
              </span>
            )}
            {settings.headline && <h3 className="mb-3">{settings.headline}</h3>}
            {settings.subline && (
              <p>{settings.subline.replace("{days}", TRIAL_DURATION)}</p>
            )}
            {settings.message && (
              <p className="text--pink">
                {settings.message.replace(
                  "{price}",
                  getFormattedValue(
                    prices[prices.length - 1].price,
                    locale,
                    currency
                  )
                )}
              </p>
            )}
          </header>
          <div className="mb-5">
            <ChoicesField
              id="setupPrice"
              columns="4"
              options={priceOptions}
              handleChange={handlePriceOptionClick}
              input={{ setupPrice: session.setupPrice }}
            />
          </div>
          <Row>
            <Col xs={{ span: 10, offset: 1 }} sm={{ span: 8, offset: 2 }}>
              <button
                className="btn btn--primary btn--block btn--rounded btn--drop-shadow-soft mb-3"
                onClick={handleContinueButtonClick}
              >
                {(loading && settings.loadingText) || session.paymentRequired
                  ? settings.buttonText
                  : settings.buttonTextNoPayment}
              </button>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default ChooseTrialStep;
