import React, { useState, useEffect } from "react";
import moment from "moment";

const Timer = ({ endTime, endCallback }) => {
  const [end, setEnd] = useState(endTime);
  const [remainingTime, setRemainingTime] = useState(0);

  useEffect(() => {
    setEnd(endTime);
  }, [endTime]);

  useEffect(() => {
    const calculateRemainingTime = () => {
      let now = moment();
      let timeDifference = moment(end).diff(now, "milliseconds");
      return timeDifference;
    };
    const updateTimer = (callback) => {
      let remaining = calculateRemainingTime();
      if (remaining > 0) {
        setRemainingTime(remaining);
      } else {
        if (callback) callback();
      }
    };
    const timeInterval = setInterval(() => {
      updateTimer(() => {
        clearInterval(timeInterval);
        if (endCallback) endCallback();
      });
    }, 1000);

    if (remainingTime > 0) updateTimer();

    return () => clearInterval(timeInterval);
  }, [remainingTime, end, endCallback]);

  return <div>{moment(remainingTime).format("mm:ss")}</div>;
};

export default Timer;
