import React, { useEffect, useContext, useState } from "react";
import { SessionContext } from "../../context/SessionContext";
import { useParams, useHistory } from "react-router-dom";
import { APP_NAME } from "../../constants";
import "./style.css";
import { Survey } from "../../util/survey";
import SurveyHeader from "../../components/SurveyHeader";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ChoicesField from "../../components/ChoicesField";
import UnitSwitcher from "../../components/UnitSwitcher";
import WeightField from "../../components/WeightField";
import HeightField from "../../components/HeightField";
import BodyTypeField from "../../components/BodyTypeField";
import InfoGraphicView from "../InfoGraphicView";

const SurveyView = () => {
  const { stepSlug, topicSlug } = useParams();
  const history = useHistory();
  const survey = Survey({
    stepPointer: stepSlug,
    history,
    topicSlug,
  });
  const [session, setSession] = useContext(SessionContext);
  const [input, setInput] = useState(session.config);

  const { step, showSubmitButton, showUnitSwitcher } = survey;
  const {
    id,
    title,
    fields,
    hideProgressBar,
    view,
    submitButtonText,
    meta,
  } = step;
  const stepId = id;
  const stepInput = input[stepId] || {};

  useEffect(() => {
    document.title = `${meta.title} - ${APP_NAME}`;
  }, [meta.title]);

  useEffect(() => {
    setSession({ ...session, config: input });
  }, [input]);

  const handleChange = (name, value) => {
    setInput((prevState) => ({
      ...prevState,
      [stepId]: {
        ...prevState[stepId],
        [name]: value,
      },
    }));
  };

  const handleSingleChoiceChange = (name, value) => {
    handleChange(name, value);
    setTimeout(() => {
      survey.navigateToNextStep();
    }, 200);
  };

  const handleMultipleChoiceChange = (name, value) => {
    setInput((prevState) => {
      let updatedValue = [];
      if (prevState[stepId] && prevState[stepId][name] && "none" !== value) {
        let currentValue = prevState[stepId][name];
        if (currentValue.includes(value)) {
          updatedValue = currentValue.filter((val) => val !== value);
        } else {
          updatedValue = [...currentValue, value];
        }
        updatedValue = updatedValue.filter((val) => "none" !== val);
      } else {
        updatedValue = [value];
      }
      return {
        ...prevState,
        [stepId]: {
          ...prevState[stepId],
          [name]: updatedValue,
        },
      };
    });
  };

  const handleWeightFieldChange = (name, value) => {
    handleChange(name, value);
  };

  const handleHeightFieldChange = (name, value) => {
    handleChange(name, value);
  };

  const handleSubmit = () => {
    survey.navigateToNextStep();
  };

  let fieldComponents = [];
  if (fields) {
    fieldComponents = fields.map((field) => {
      let { id, type, options, columns, optional } = field;
      switch (type) {
        case "multipleChoice":
          if (optional) {
            options = [
              ...options,
              {
                id: "none",
                title: "None",
                value: "none",
              },
            ];
          }
          return (
            <ChoicesField
              id={id}
              options={options}
              handleChange={handleMultipleChoiceChange}
              key={id}
              input={stepInput}
              columns={columns}
              optional={optional}
            />
          );
        case "bodyTypeChoice":
          return (
            <BodyTypeField
              id={id}
              handleChange={handleSingleChoiceChange}
              key={id}
              input={stepInput}
            />
          );
        case "heightField":
          return (
            <HeightField
              id={id}
              handleChange={handleHeightFieldChange}
              key={id}
              input={stepInput}
            />
          );
        case "weightField":
          return (
            <WeightField
              id={id}
              handleChange={handleWeightFieldChange}
              key={id}
              input={stepInput}
            />
          );

        default:
          return (
            <ChoicesField
              id={id}
              options={options}
              handleChange={handleSingleChoiceChange}
              key={id}
              input={stepInput}
              columns={columns}
            />
          );
      }
    });
  }

  const InputView = (
    <>
      <header className="d-flex justify-content-center mb-4">
        <strong className="survey__question">{title}</strong>
      </header>
      {showUnitSwitcher && (
        <div className="d-flex justify-content-center">
          <UnitSwitcher className="mb-4" />
        </div>
      )}
      <div className="survey__input">
        <div className="mb-4">
          {fieldComponents.map((fieldComponent, index) => (
            <div className="survey__row mb-3" key={index}>
              {fieldComponent}
            </div>
          ))}
        </div>
      </div>
    </>
  );

  return (
    <Container>
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <div className="survey">
            {!hideProgressBar && <SurveyHeader className="mb-5"></SurveyHeader>}
            {(view && view === "infoGraphic" && <InfoGraphicView />) ||
              InputView}
            {showSubmitButton && (
              <Row>
                <Col xs={{ span: 10, offset: 1 }} md={{ span: 8, offset: 2 }}>
                  <button
                    onClick={handleSubmit}
                    className="btn btn--primary btn--block btn--rounded"
                  >
                    {(submitButtonText && submitButtonText) || "Continue"}
                  </button>
                </Col>
              </Row>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default SurveyView;
