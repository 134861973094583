import React from "react";
import { Switch, Route, useParams } from "react-router-dom";
import SimpleHeader from "../../components/SimpleHeader";
import RegistrationStep from "../signup/RegistrationStep";
import ChooseTrialStep from "../signup/ChooseTrialStep";
import PaymentStep from "../signup/PaymentStep";
import StartStep from "../signup/StartStep";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const SignupView = () => {
  const { topicSlug } = useParams();
  const baseUrl = topicSlug ? `/topic/${topicSlug}` : "";
  return (
    <>
      <SimpleHeader className="mb-5" />
      <Container>
        <Row>
          <Col md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
            <Switch>
              <Route path={[`/signup/start`, `/topic/:topicSlug/signup/start`]}>
                <StartStep baseUrl={baseUrl} />
              </Route>
              <Route
                path={[
                  `/signup/registration`,
                  `/topic/:topicSlug/signup/registration`,
                ]}
              >
                <RegistrationStep baseUrl={baseUrl} />
              </Route>
              <Route
                path={[
                  `/signup/choose-trial`,
                  `/topic/:topicSlug/signup/choose-trial`,
                ]}
              >
                <ChooseTrialStep baseUrl={baseUrl} />
              </Route>
              <Route
                path={[`/signup/payment`, `/topic/:topicSlug/signup/payment`]}
              >
                <PaymentStep baseUrl={baseUrl} />
              </Route>
            </Switch>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SignupView;
