export const getConfigFromStorage = (step = "", field = "") => {
  let config = localStorage.getItem("config")
    ? JSON.parse(localStorage.getItem("config"))
    : {};
  if (!step || !config[step]) return config;
  if (field && config[step][field]) return config[step][field];
  return config[step];
};

export const updateConfigToStorage = (config = {}) => {
  localStorage.setItem("config", JSON.stringify(config));
};

export const getUnitTypeFromStorage = () => {
  return localStorage.getItem("unitType");
};

export const updateUnitTypeToStorage = (unitType = "imperial") => {
  if (!["imperial", "metric"].includes(unitType)) return;
  localStorage.setItem("unitType", unitType);
};

export const getCurrencyFromStorage = () => {
  return localStorage.getItem("currency");
};

export const updateCurrencyToStorage = (currency) => {
  if (currency) localStorage.setItem("currency", currency);
};

export const getCountryCodeFromStorage = () => {
  return localStorage.getItem("countryCode");
};

export const updateCountryCodeToStorage = (countryCode) => {
  if (countryCode) localStorage.setItem("countryCode", countryCode);
};

export const getLocaleFromStorage = () => {
  return localStorage.getItem("locale");
};

export const updateLocaleToStorage = (locale) => {
  if (locale) localStorage.setItem("locale", locale);
};

export const getExchangeRateFromStorage = () => {
  return localStorage.getItem("exchangeRate"); // To USD
};

export const updateExchangeRateToStorage = (exchangeRate) => {
  if (exchangeRate) localStorage.setItem("exchangeRate", exchangeRate);
};

export const getClientIdFromStorage = () => {
  return localStorage.getItem("clientId");
};

export const updateClientIdToStorage = (clientId) => {
  if (clientId) localStorage.setItem("clientId", clientId);
};

export const getUserEmailFromStorage = () => {
  return localStorage.getItem("userEmail");
};

export const updateUserEmailToStorage = (userEmail) => {
  if (userEmail) localStorage.setItem("userEmail", userEmail);
};

export const getNameFromStorage = () => {
  return localStorage.getItem("name");
};

export const updateNameToStorage = (name) => {
  if (name) localStorage.setItem("name", name);
};

export const getSessionIdFromStorage = () => {
  return localStorage.getItem("sessionId");
};

export const updateSessionIdToStorage = (sessionId) => {
  if (sessionId) localStorage.setItem("sessionId", sessionId);
};

export const getOfferEndTimeFromStorage = () => {
  return localStorage.getItem("offerEndTime");
};

export const updateOfferEndTimeToStorage = (offerEndTime) => {
  if (offerEndTime) localStorage.setItem("offerEndTime", offerEndTime);
};

export const removeOfferEndTimeFromStorage = () => {
  localStorage.removeItem("offerEndTime");
};

export const updateVariantToStorage = (variant) => {
  if (variant) localStorage.setItem("variant", variant);
};

export const getVariantFromStorage = () => {
  return localStorage.getItem("variant");
};

export const updateUserIdToStorage = (userId) => {
  if (userId) localStorage.setItem("userId", userId);
};

export const getUserIdFromStorage = () => {
  return localStorage.getItem("userId");
};
