import React from "react";
import "./style.scss";

const Switcher = ({ className, options, handleSwitch, activeOptionId }) => {
  let optionComponents = [];

  if (options) {
    optionComponents = options.map((option) => {
      let optionClass = "switcher__option";
      if (option.id && activeOptionId && option.id === activeOptionId)
        optionClass += " is-active";
      return (
        <div
          className={optionClass}
          key={option.id}
          onClick={() => handleSwitch(option.id)}
        >
          {option.title}
        </div>
      );
    });
  }

  return (
    <div className={`switcher ${className}`}>
      {optionComponents.length > 0 && optionComponents}
    </div>
  );
};
export default Switcher;
