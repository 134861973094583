import { createContext, useEffect, useState } from "react";
import useQuery from "../hooks/useQuery";

export const ExperimentContext = createContext();

export const ExperimentProvider = (props) => {
  const query = useQuery();
  const [experiment, setExperiment] = useState({ fv: 1, pv: "k3js74hs" });

  useEffect(() => {
    if (query.get("exp")) {
      if (query.get("fv")) {
        setExperiment((prevState) => ({
          ...prevState,
          fv: parseInt(query.get("fv")),
        }));
      }
      if (query.get("pv")) {
        setExperiment((prevState) => ({
          ...prevState,
          pv: query.get("pv"),
        }));
      }
    } else if (localStorage.getItem("experiment")) {
      let storedExperiment = JSON.parse(localStorage.getItem("experiment"));
      setExperiment((prevState) => ({ ...prevState, ...storedExperiment }));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("experiment", JSON.stringify(experiment));
  }, [experiment]);

  return (
    <ExperimentContext.Provider value={[experiment, setExperiment]}>
      {props.children}
    </ExperimentContext.Provider>
  );
};
