import React, { useState, useEffect } from "react";
import { APP_NAME } from "../../constants";
import { useHistory } from "react-router-dom";
import { eventToDatalayer } from "../../util/tracking";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const ProcessingView = () => {
  const history = useHistory();
  const [percentage, setPercentage] = useState(5);
  const [messageIndex, setMessageIndex] = useState(0);
  const messages = [
    "Processing your responses..",
    "Analyzing your dietary profile..",
    "Estimating your metabolic age..",
  ];

  useEffect(() => {
    document.title = `Processing - ${APP_NAME}`;
    eventToDatalayer("optimize.activate");
  }, []);

  useEffect(() => {
    const redirect = () => {
      let redirectAfter = localStorage.getItem("redirectAfter");
      if (redirectAfter) {
        localStorage.removeItem("redirectAfter");
        history.push(redirectAfter);
      }
    };
    const percentageInterval = setInterval(() => {
      let add = Math.floor(Math.random() * 30);
      let updatedPercentage = percentage + add;
      if (updatedPercentage >= 100) updatedPercentage = 100;
      setPercentage(updatedPercentage);
      if (percentage >= 100) {
        redirect();
      }
    }, 1200);
    return () => clearInterval(percentageInterval);
  });

  useEffect(() => {
    const messageIndexInterval = setInterval(() => {
      let updatedIndex = messageIndex;
      if (updatedIndex >= messages.length - 1) {
        clearInterval(messageIndexInterval);
      } else {
        updatedIndex += 1;
      }
      setMessageIndex(updatedIndex);
    }, 3000);
    return () => clearInterval(messageIndexInterval);
  }, [messageIndex, messages.length]);

  return (
    <Container>
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <Row>
            <Col xs={{ span: 8, offset: 2 }}>
              <div className="pt-5 mb-5">
                <CircularProgressbar
                  value={percentage}
                  text={`${percentage}%`}
                  styles={buildStyles({
                    rotation: 0,
                    strokeLinecap: "butt",
                    textSize: "1.5em",
                    pathTransitionDuration: 0.5,
                    pathColor: `rgba(89, 92, 193, 100)`,
                    textColor: "#000",
                    trailColor: "#d8d8d8",
                  })}
                />
              </div>
            </Col>
          </Row>
          <div className="text-center">{messages[messageIndex]}</div>
        </Col>
      </Row>
    </Container>
  );
};

export default ProcessingView;
