import axios from "axios";

const createUser = async (email, password) => {
  if (!email || !password) return;

  const response = await axios.post(
    process.env.REACT_APP_API_URL + "/auth/create-user",
    {
      email,
      password,
    }
  );
  if (response) {
    return response.data.error
      ? { error: response.data.error }
      : response.data.user;
  }
};

const signAdminIn = async (email, password) => {
  if (!email || !password) return;

  const response = await axios.get(
    process.env.REACT_APP_API_URL + "/auth/admin/sign-in",
    {
      params: {
        email,
        password,
      },
    }
  );
  if (response.data) {
    if (!response.data.error) {
      localStorage.setItem("authToken", response.data.authToken);
    }
    return response.data;
  }
};

const verifyAuthToken = async (authToken) => {
  if (!authToken) return;
  const response = await axios.get(
    process.env.REACT_APP_API_URL + "/auth/verify-token",
    {
      params: {
        authToken,
      },
    }
  );
  return (response.data && response.data.verified) || false;
};

export { createUser, signAdminIn, verifyAuthToken };
