import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import gaAnalytics from "../../util/ga";
import { APP_NAME } from "../../constants";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import productPreviewImage from "../../images/product-preview-transparent.png";
import { SessionContext } from "../../context/SessionContext";
import { addSessionEventToDb } from "../../util/sessions";

const StartStep = ({ baseUrl }) => {
  const [session] = useContext(SessionContext);
  const [settings, setSettings] = useState({
    stepProgress: true,
    headline: "Finish setting up your account",
    subline:
      "Foodimus is personalized for you. Leave your email to use Foodimus on any device at any time.",
    buttonText: "Continue",
  });

  const history = useHistory();

  useEffect(() => {
    document.title = `Start signup - ${APP_NAME}`;
  }, []);

  const handleContinueButtonClick = () => {
    gaAnalytics.sendEvent({
      category: "Wizard",
      action: "Click",
      label: "Start signup",
    });
    addSessionEventToDb(session.sessionId, "startSignupButtonClick");
    history.push(`${baseUrl}/signup/registration`);
  };

  return (
    <div>
      <Row>
        <Col xs={{ span: 10, offset: 1 }} md={{ span: 8, offset: 2 }}>
          <header className="mb-5 text-center">
            <div className="mb-3">
              <img
                src={productPreviewImage}
                alt="Product preview"
                width="100%"
              />
            </div>
            {settings.stepProgress && (
              <span className="text-uppercase d-block mb-2">Step 1 of 3</span>
            )}
            {settings.headline && <h3 className="mb-3">{settings.headline}</h3>}
            {settings.subline && <p>{settings.subline}</p>}
          </header>
          <button
            className="btn btn--primary btn--block btn--rounded btn--drop-shadow-soft"
            onClick={handleContinueButtonClick}
          >
            {settings.buttonText}
          </button>
        </Col>
      </Row>
    </div>
  );
};

export default StartStep;
