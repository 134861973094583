import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, ProgressBar } from "react-bootstrap";
import structure from "../../../data/structure";

export const StatsView = () => {
  const [stats, setStats] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "/stats/sessions"
      );
      if (response.status === 200) {
        setStats(response.data);
      }
    })();
  }, []);

  let steps = [];
  structure.phases.forEach((phase) => {
    phase.steps.forEach((step) => steps.push(step));
  });
  const getStep = (stepId) => {
    return steps.find((step) => step.id === stepId) || false;
  };

  const getStepTitle = (stepId) => {
    const step = getStep(stepId);
    return (step && step.title) || false;
  };

  const getFieldAnswerTitle = (stepId, fieldId, optionId) => {
    const step = getStep(stepId);
    if (!step) return;
    const field = step.fields.find((field) => field.id === fieldId);
    if (!field) return;
    const option =
      field.options && field.options.find((option) => option.id === optionId);
    return option ? option.title : false;
  };

  return (
    <div className="p-4">
      <Container fluid>
        <header className="mb-5">
          <h1>Stats</h1>
        </header>
        <section>
          {(stats.config && (
            <Row>
              {stats.config.map((step) => (
                <Col md="4" lg="3" xl="2">
                  <Card className="board-block mb-5">
                    <Card.Header>
                      <strong>
                        {getStepTitle(step.id)} ({step.count})
                      </strong>
                    </Card.Header>
                    <div className="board-block__part p-4">
                      {step.fields &&
                        step.fields.map((field) => (
                          <>
                            <h5 className="board-block__part-title">
                              Field: {field.id}
                            </h5>
                            {field.answers &&
                              field.answers.map((answer) => {
                                let answerTitle = getFieldAnswerTitle(
                                  step.id,
                                  field.id,
                                  answer.value
                                );
                                return (
                                  <div className="board-results">
                                    <div className="board-result mb-3">
                                      <span className="board-result__title mb-1 d-block">
                                        <strong>
                                          {answerTitle || answer.value}
                                        </strong>{" "}
                                        ({answer.count})
                                      </span>
                                      <ProgressBar
                                        variant="success"
                                        label={
                                          Math.ceil(
                                            answer.countPercentage * 100
                                          ) + "%"
                                        }
                                        now={answer.countPercentage * 100}
                                      />
                                    </div>
                                  </div>
                                );
                              })}
                          </>
                        ))}
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          )) ||
            "Loading stats.."}
        </section>
      </Container>
    </div>
  );
};

export default StatsView;
