const structure = {
  phases: [
    {
      id: "demographicProfile",
      title: "Demographic profile",
      steps: [
        {
          id: "gender",
          slug: "gender",
          title: "What is your gender?",
          meta: {
            title: "Gender",
          },
          fields: [
            {
              id: "gender",
              type: "singleChoice",
              options: [
                {
                  id: "female",
                  title: "Female",
                  value: "female",
                },
                {
                  id: "male",
                  title: "Male",
                  value: "male",
                },
              ],
            },
          ],
        },
        {
          id: "goal",
          slug: "goal",
          title: "What is your primary goal?",
          disabledTopics: ["cycling", "vegan"],
          meta: {
            title: "Goal",
          },
          fields: [
            {
              id: "goal",
              type: "singleChoice",
              options: [
                {
                  id: "loseWeight",
                  title: "Lose weight",
                  value: "loseWeight",
                },
                {
                  id: "gainMuscle",
                  title: "Gain muscle",
                  value: "gainMuscle",
                },
                {
                  id: "healthyHabits",
                  title: "Develop healthy habits",
                  value: "healthy-habits",
                },
              ],
            },
          ],
        },
        {
          id: "goal",
          slug: "goal",
          title: "What is your primary goal?",
          enabledTopics: ["cycling"],
          meta: {
            title: "Goal",
          },
          fields: [
            {
              id: "goal",
              type: "singleChoice",
              options: [
                {
                  id: "loseWeight",
                  title: "Lose weight",
                  value: "loseWeight",
                },
                {
                  id: "startCycling",
                  title: "Start cycling",
                  value: "startCycling",
                },
                {
                  id: "stayFit",
                  title: "Stay fit",
                  value: "stayFit",
                },
                {
                  id: "healthyHabits",
                  title: "Improve performance",
                  value: "healthy-habits",
                },
                {
                  id: "PrepareForRace",
                  title: "Prepare for a race",
                  value: "PrepareForRace",
                },
              ],
            },
          ],
        },
        {
          id: "goal",
          slug: "goal",
          title: "What is your primary goal?",
          enabledTopics: ["vegan"],
          meta: {
            title: "Goal",
          },
          fields: [
            {
              id: "goal",
              type: "singleChoice",
              options: [
                {
                  id: "personalHealth",
                  title: "Personal health",
                  value: "personalHealth",
                },
                {
                  id: "animalWelfare",
                  title: "Animal welfare",
                  value: "animalWelfare",
                },
                {
                  id: "environment",
                  title: "Environment",
                  value: "environment",
                },
              ],
            },
          ],
        },
        {
          id: "idealWeight",
          slug: "ideal-weight",
          title: "What is your ideal weight that you want to reach?",
          meta: {
            title: "Ideal weight",
          },
          fields: [
            {
              id: "idealWeight",
              type: "weightField",
            },
          ],
        },
        {
          id: "measurements",
          slug: "measurements",
          title: "What is your current height and weight?",
          meta: {
            title: "Measurements",
          },
          fields: [
            {
              id: "height",
              type: "heightField",
            },
            {
              id: "weight",
              type: "weightField",
            },
          ],
        },
        {
          id: "age",
          slug: "age",
          title: "What is your age?",
          meta: {
            title: "Age",
          },
          fields: [
            {
              id: "age",
              type: "singleChoice",
              options: [
                {
                  id: "20s",
                  title: "20s",
                  value: "20",
                },
                {
                  id: "30s",
                  title: "30s",
                  value: "30",
                },
                {
                  id: "40s",
                  title: "40s",
                  value: "40",
                },
                {
                  id: "50s",
                  title: "50s",
                  value: "50",
                },
                {
                  id: "60s",
                  title: "60s",
                  value: "60",
                },
                {
                  id: "70s",
                  title: "70s",
                  value: "70",
                },
                {
                  id: "80s",
                  title: "80s",
                  value: "80",
                },
              ],
            },
          ],
        },
        {
          id: "bodyType",
          slug: "body-type",
          title: "What is your body type?",
          meta: {
            title: "Body type",
          },
          fields: [
            {
              id: "bodyType",
              type: "bodyTypeChoice",
            },
          ],
        },
      ],
    },
    {
      id: "lifestyle",
      title: "Lifestyle",
      steps: [
        {
          id: "typicalDay",
          slug: "typical-day",
          title: "How does your typical day look like?",
          meta: {
            title: "Typical day",
          },
          fields: [
            {
              id: "typicalDay",
              type: "singleChoice",
              options: [
                {
                  id: "office",
                  title: "At the office",
                  value: "office",
                },
                {
                  id: "dailyWalks",
                  title: "Daily long walks",
                  value: "dailyWalks",
                },
                {
                  id: "physical",
                  title: "Physical work",
                  value: "physical",
                },
                {
                  id: "mostlyHome",
                  title: "Mostly at home",
                  value: "mostlyHome",
                },
              ],
            },
          ],
        },
        {
          id: "infoGraphic",
          slug: "info-graphic",
          title: "Behavior Change Vs. Restrictive Diet",
          meta: {
            title: "Behavior Change Vs. Restrictive Diet",
          },
          hideProgressBar: true,
          showSubmitButton: true,
          submitButtonText: "Ok, got it",
          view: "infoGraphic",
        },
        {
          id: "idealWeightPeriod",
          slug: "ideal-weight-period",
          title: "How long has it been since you were at your ideal weight?",
          meta: {
            title: "How long since ideal weight",
          },
          fields: [
            {
              id: "typicalDay",
              type: "singleChoice",
              options: [
                {
                  id: "lessThan1Year",
                  title: "Less than 1 year",
                  value: "lessThan1Year",
                },
                {
                  id: "1to2Years",
                  title: "1-2 years",
                  value: "1to2Years",
                },
                {
                  id: "moreThan3Years",
                  title: "More than 3 years",
                  value: "moreThan3Years",
                },
                {
                  id: "never",
                  title: "Never",
                  value: "never",
                },
              ],
            },
          ],
        },
        {
          id: "badHabits",
          slug: "bad-habits",
          title: "Do you have bad habits?",
          meta: {
            title: "Bad habits",
          },
          fields: [
            {
              id: "badHabits",
              type: "multipleChoice",
              optional: true,
              options: [
                {
                  id: "lateNightEating",
                  title: "I eat late at night",
                  value: "lateNightEating",
                },
                {
                  id: "lackOfSleep",
                  title: "I don't get enough sleep",
                  value: "lackOfSleep",
                },
                {
                  id: "sweets",
                  title: "Can't give up sweets",
                  value: "sweets",
                },
                {
                  id: "softDrinks",
                  title: "I love soft drinks",
                  value: "softDrinks",
                },
                {
                  id: "salt",
                  title: "I consume a lot of salt",
                  value: "salt",
                },
              ],
            },
          ],
        },
        {
          id: "physicallyActive",
          slug: "physically-active",
          title: "How physically active are you?",
          meta: {
            title: "Physically active",
          },
          fields: [
            {
              id: "physicallyActive",
              type: "singleChoice",
              options: [
                {
                  id: "barelyAnyActivity",
                  title: "Barely any activity",
                  value: "barelyAnyActivity",
                },
                {
                  id: "1to2",
                  title: "Fitness 1-2 times a week",
                  value: "1to2",
                },
                {
                  id: "3to5",
                  title: "Fitness 3-5 times a week",
                  value: "3to5",
                },
                {
                  id: "5to7",
                  title: "Fitness 5-7 times a week",
                  value: "5to7",
                },
              ],
            },
          ],
        },
        {
          id: "energy",
          slug: "energy",
          title: "Are you able to maintain your energy during the day?",
          meta: {
            title: "Energy during the day",
          },
          fields: [
            {
              id: "physicallyActive",
              type: "singleChoice",
              options: [
                {
                  id: "noFluctuation",
                  title: "My energy levels do not fluctuate",
                  value: "noFluctuation",
                },
                {
                  id: "dragBeforeMeals",
                  title: "I drag before meals",
                  value: "dragBeforeMeals",
                },
                {
                  id: "sleepyAfterLunch",
                  title: "I feel sleepy after lunch",
                  value: "sleepyAfterLunch",
                },
              ],
            },
          ],
        },
        {
          id: "sleep",
          slug: "sleep",
          title: "How much do you normally sleep?",
          meta: {
            title: "Sleep",
          },
          fields: [
            {
              id: "sleep",
              type: "singleChoice",
              options: [
                {
                  id: "lessThan5",
                  title: "Less than 5 hours",
                  value: "lessThan5",
                },
                {
                  id: "5to6",
                  title: "5-6 hours",
                  value: "5to6",
                },
                {
                  id: "7to8",
                  title: "7-8 hours",
                  value: "7to8",
                },
                {
                  id: "moreThan8",
                  title: "More than 8 hours",
                  value: "moreThan8",
                },
              ],
            },
          ],
        },
        {
          id: "water",
          slug: "water",
          title: "How much water do you drink daily?",
          meta: {
            title: "Water",
          },
          fields: [
            {
              id: "water",
              type: "singleChoice",
              options: [
                {
                  id: "onlyCoffee",
                  title: "Only coffee or tea",
                  value: "onlyCoffee",
                },
                {
                  id: "lessThan2",
                  title: "Less than 2 glasses",
                  value: "lessThan2",
                },
                {
                  id: "2to6",
                  title: "2-6 glasses",
                  value: "2to6",
                },
                {
                  id: "moreThan6",
                  title: "More than 6 glasses",
                  value: "moreThan6",
                },
              ],
            },
          ],
        },
        {
          id: "motivation",
          slug: "motivation",
          title:
            "Do you require external motivation to stay by your new habits?",
          meta: {
            title: "Motivation",
          },
          fields: [
            {
              id: "motivation",
              type: "singleChoice",
              columns: 2,
              options: [
                {
                  id: "yes",
                  title: "Yes",
                  value: "yes",
                },
                {
                  id: "no",
                  title: "No",
                  value: "no",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: "foodPreference",
      title: "Food preference",
      steps: [
        {
          id: "vegetables",
          slug: "vegetables",
          title: "Which vegetables do you want to include in your meal plan?",
          meta: {
            title: "Vegetables",
          },
          fields: [
            {
              id: "vegetables",
              type: "multipleChoice",
              columns: 2,
              options: [
                {
                  id: "broccoli",
                  title: "Broccoli",
                  value: "broccoli",
                },
                {
                  id: "sweetPotato",
                  title: "Sweet potato",
                  value: "sweetPotato",
                },
                {
                  id: "mushrooms",
                  title: "Mushrooms",
                  value: "mushrooms",
                },
                {
                  id: "tomato",
                  title: "Tomato",
                  value: "tomato",
                },
                {
                  id: "peas",
                  title: "Peas",
                  value: "peas",
                },
                {
                  id: "pepper",
                  title: "Pepper",
                  value: "pepper",
                },
                {
                  id: "spinach",
                  title: "Spinach",
                  value: "spinach",
                },
                {
                  id: "zucchini",
                  title: "Zucchini",
                  value: "zucchini",
                },
              ],
            },
          ],
        },
        {
          id: "products",
          slug: "products",
          title: "Which products do you want to include in your meal plan?",
          meta: {
            title: "Products",
          },
          fields: [
            {
              id: "products",
              type: "multipleChoice",
              columns: 2,
              optional: true,
              options: [
                {
                  id: "avocado",
                  title: "Avocado",
                  value: "avocado",
                },
                {
                  id: "eggs",
                  title: "Eggs",
                  value: "eggs",
                  disabledTopics: ["vegan"],
                },
                {
                  id: "yoghurt",
                  title: "Yoghurt",
                  value: "yoghurt",
                  disabledTopics: ["vegan"],
                },
                {
                  id: "cottageCheese",
                  title: "Cottage cheese",
                  value: "cottageCheese",
                  disabledTopics: ["vegan"],
                },
                {
                  id: "tofu",
                  title: "Tofu",
                  value: "tofu",
                },
                {
                  id: "olives",
                  title: "Olives",
                  value: "olives",
                },
                {
                  id: "peanutButter",
                  title: "Peanut butter",
                  value: "peanutButter",
                },
                {
                  id: "nuts",
                  title: "Nuts",
                  value: "nuts",
                },
              ],
            },
          ],
        },
        {
          id: "meat",
          slug: "meat",
          title:
            "Which kinds of meat do you want to include in your meal plan?",
          disabledTopics: ["vegan"],
          meta: {
            title: "Meat",
          },
          fields: [
            {
              id: "meat",
              type: "multipleChoice",
              columns: 2,
              optional: true,
              options: [
                {
                  id: "turkey",
                  title: "Turkey",
                  value: "turkey",
                },
                {
                  id: "fish",
                  title: "Fish",
                  value: "fish",
                },
                {
                  id: "beef",
                  title: "Beef",
                  value: "beef",
                },
                {
                  id: "chicken",
                  title: "Chicken",
                  value: "chicken",
                },
                {
                  id: "pork",
                  title: "Pork",
                  value: "pork",
                },
              ],
            },
          ],
        },
        {
          id: "mealPrepTime",
          slug: "meal-prep-time",
          title:
            "One last thing, how much time do you have for meal preperation every day?",
          meta: {
            title: "Meal prep time",
          },
          loaderAfter: true,
          fields: [
            {
              id: "mealPrepTime",
              type: "SingleChoice",
              options: [
                {
                  id: "lessThan30",
                  title: "Less than 30 min",
                  value: "lessThan30",
                },
                {
                  id: "30to60",
                  title: "30-60 min",
                  value: "30to60",
                },
                {
                  id: "moreThan60",
                  title: "More than 1 hour",
                  value: "moreThan60",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export default structure;
