import axios from "axios";

const createPaymentIntent = async ({
  priceType,
  priceId,
  currency,
  sessionId,
}) => {
  const response = await axios.post(
    process.env.REACT_APP_API_URL + "/payments/stripe/create-intent",
    { priceType, priceId, currency, sessionId }
  );
  if (response && response.status === 200) {
    return response.data;
  }
};

const createSubscription = async ({
  paymentMethod,
  email,
  name,
  prices,
  currency,
  sessionId,
}) => {
  const response = await axios.post(
    process.env.REACT_APP_API_URL + "/payments/stripe/create-subscription",
    {
      paymentMethod,
      email,
      name,
      prices,
      currency,
      sessionId,
    }
  );
  return response.data;
};

export { createPaymentIntent, createSubscription };
