import React from "react";
import moment from "moment";
import { TRIAL_DURATION } from "../../constants";
import Price from "../Price";
import usePrices from "../../hooks/usePrices";

const TrialTerms = () => {
  const prices = usePrices();
  const trialEndDate = moment().add(TRIAL_DURATION, "days");
  const trialEndDateFormatted = moment(trialEndDate).format("MMMM Do, y");

  return (
    <div className="p-2 text--small text-center">
      <p className="mb-0">
        <strong>
          *Your {TRIAL_DURATION} day trial will last until{" "}
          {trialEndDateFormatted}.
        </strong>{" "}
        You can cancel anytime before then and will not be charged the full
        program amount. No questions asked, no small print. Unless you cancel
        beforehand,{" "}
        <strong>
          on {trialEndDateFormatted} your will be charged{" "}
          <Price value={prices.total} />
        </strong>
        . Foodimus will automatically charge your card{" "}
        <Price value={prices.total} /> every month (
        <Price value={prices.total / 4} />
        /week) so you don't lose access to your account. No refunds or credits
        for partial months. <strong>You can cancel anytime online</strong>.
      </p>
    </div>
  );
};

export default TrialTerms;
