import { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  CardElement,
  PaymentRequestButtonElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import usePrices from "../../hooks/usePrices";
import gaAnalytics from "../../util/ga";
import ReactPixel from "react-facebook-pixel";
import { SessionContext } from "../../context/SessionContext";
import { PreferenceContext } from "../../context/PreferenceContext";
import { ExperimentContext } from "../../context/ExperimentContext";
import { addSessionEventToDb } from "../../util/sessions";
import { createSubscription, createPaymentIntent } from "../../util/stripe";
import { getFormattedValue } from "../../util/money";
import { redirectToDashboard } from "../../util/dashboard";
import { APP_NAME, TRIAL_DURATION } from "../../constants";
import { Row, Col } from "react-bootstrap";

import TrialTerms from "../../components/TrialTerms";
import creditCardIcons from "../../images/credit-card-icons.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const PaymentStep = () => {
  const [settings, setSettings] = useState({
    title: "Activate Trial",
    stepProgress: "Step 3 of 3",
    headline: "Activate your {days} day trial",
    cardIcons: true,
    buttonText: "Pay {price} to start trial",
    buttonCaption: "No commitment - cancel any time",
    terms: true,
  });

  const { topicSlug } = useParams();
  const stripe = useStripe();
  const elements = useElements();
  const [preferences] = useContext(PreferenceContext);
  const [session] = useContext(SessionContext);
  const [experiment] = useContext(ExperimentContext);
  const { currency, locale, countryCode } = preferences;
  const { sessionId, email } = session;
  const [cardElementFocused, setCardElementFocused] = useState(false);
  const [cardDetailsEntered, setCardDetailsEntered] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const prices = usePrices();
  const [paymentRequest, setPaymentRequest] = useState(null);

  useEffect(() => {
    document.title = `${settings.title} - ${APP_NAME}`;
  }, [settings.title]);

  useEffect(() => {
    if (!experiment) return;
    if (!experiment.fv) return;
    switch (experiment.fv) {
      case 2:
        setSettings((prevState) => ({
          ...prevState,
          title: "Your contribution",
          headline: "Your one time contribution",
          buttonText: "Contribute {price} one time",
          buttonCaption: "Safe & secure payment",
          terms: false,
        }));
        break;
      default:
    }
  }, [experiment]);

  const showDefaultErrorMessage = () => {
    setErrorAlert("Something went wrong, please try again.");
  };

  const handleCardElementFocus = () => {
    setCardElementFocused(!cardElementFocused);
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleTermsCheckboxChange = (event) => {
    if (event.target.checked) setTermsChecked(true);
    else setTermsChecked(false);
  };

  const handleCardElementChange = (event) => {
    if (event.complete) {
      setCardDetailsEntered(true);
      setErrorAlert(false);
    } else {
      setCardDetailsEntered(false);
    }
  };

  const handleSuccessfullPayment = (name) => {
    if (currency && prices.setupPrice)
      ReactPixel.track("Purchase", { currency, value: prices.setupPrice });

    redirectToDashboard({
      name,
      email,
      topicSlug,
    });
  };

  const handlePaymentRequestButtonClick = async () => {
    gaAnalytics.sendEvent({
      category: "Wizard",
      action: "Click",
      label: "Purchase button",
      value: "Payment request button",
    });
    await addSessionEventToDb(sessionId, "purchaseButtonClick");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!firstName) {
      setErrorAlert("Please enter your first name.");
      return;
    } else if (!lastName) {
      setErrorAlert("Please enter your last name.");
      return;
    } else if (!cardDetailsEntered) {
      setErrorAlert("Please enter your card details.");
      return;
    } else if (!termsChecked) {
      setErrorAlert("Please accepts the terms of service.");
      return;
    } else {
      setErrorAlert(false);
    }

    if (!stripe || !elements || !sessionId) return;

    const cardElement = elements.getElement(CardElement);

    try {
      setLoading(true);
      gaAnalytics.sendEvent({
        category: "Wizard",
        action: "Click",
        label: "Purchase button",
        value: "Credit card submit button",
      });
      await addSessionEventToDb(sessionId, "acceptTerms");
      await addSessionEventToDb(sessionId, "purchaseButtonClick");

      let name = firstName + " " + lastName;
      const result = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          name,
          email,
        },
      });

      let success = true;

      if (!result.error) {
        const { client_secret, status, error } = await createSubscription({
          paymentMethod: result.paymentMethod.id,
          email,
          name,
          currency,
          sessionId,
          prices: {
            subscription: {
              id: session.totalPrice,
            },
            trial: {
              id: session.setupPrice,
            },
          },
        });
        if (!error) {
          if (status && status === "requires_action")
            stripe.confirmCardPayment(client_secret);
        } else {
          success = false;
          if (error.code === "card_declined") {
            setErrorAlert(
              "Your card has been declined. Please try another card."
            );
            return;
          } else if (error.code === "expired_card") {
            setErrorAlert(
              "Your card has been expired. Please try another card."
            );
            return;
          }
        }
      }
      if (success) {
        setLoading(false);
        handleSuccessfullPayment(name);
      } else {
        showDefaultErrorMessage();
        setLoading(false);
      }
    } catch (err) {
      showDefaultErrorMessage();
      setLoading(false);
      console.error(err);
    }
  };

  useEffect(() => {
    if (stripe && prices && prices.setup) {
      (async () => {
        const payRequest = stripe.paymentRequest({
          country: countryCode,
          currency: currency.toLowerCase(),
          total: {
            label: "Trial",
            amount: prices.setup * 100,
          },
          requestPayerName: true,
          requestPayerEmail: true,
        });

        payRequest.on("paymentmethod", async (event) => {
          setLoading(true);
          const paymentMethod = event.paymentMethod;
          if (paymentMethod) {
            let customerName = paymentMethod.billing_details.name;
            const { client_secret, status, error } = await createSubscription({
              paymentMethod: paymentMethod.id,
              email,
              name: customerName,
              currency,
              sessionId,
              prices: {
                subscription: {
                  id: session.totalPrice,
                },
                trial: {
                  id: session.setupPrice,
                },
              },
            });
            if (!error) {
              if (status === "requires_action") {
                let result = stripe.confirmCardPayment(client_secret);
                if (!result.error) handleSuccessfullPayment(customerName);
                else {
                  event.complete("success");
                  setLoading(false);
                }
              } else if (status === "succeeded") {
                event.complete("success");
                handleSuccessfullPayment(customerName);
              } else {
                console.error(error);
                showDefaultErrorMessage();
              }
            } else {
              console.error(error);
              showDefaultErrorMessage();
            }
          } else {
            event.complete("fail");
          }
        });

        // Check the availability of the Payment Request API.
        let canMakePayment = await payRequest.canMakePayment();
        if (canMakePayment) setPaymentRequest(payRequest);
      })();
    }
  }, [stripe, prices, session.setupPrice, sessionId]);

  return (
    <div>
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <header className="mb-5 text-center">
            {settings.stepProgress && (
              <span className="text-uppercase d-block mb-2">
                {settings.stepProgress}
              </span>
            )}
            {settings.headline && (
              <h3 className="mb-3">
                {settings.headline.replace("{days}", TRIAL_DURATION)}
              </h3>
            )}
          </header>
          <div>
            {paymentRequest && (
              <>
                <PaymentRequestButtonElement
                  options={{ paymentRequest }}
                  onClick={handlePaymentRequestButtonClick}
                />
                <div className="p-4 text-center">Or with credit card</div>
              </>
            )}
            <Row className="mb-3">
              <Col xs={{ span: 8, offset: 2 }}>
                <img src={creditCardIcons} alt="Credit card icons" />
              </Col>
            </Row>
            <form onSubmit={handleSubmit}>
              {errorAlert && (
                <div className="mb-3">
                  <p className="alert alert-danger">{errorAlert}</p>
                </div>
              )}
              <div className="mb-4">
                <div className="mb-3">
                  <input
                    type="text"
                    className="formal-field"
                    placeholder="First name"
                    name="firstName"
                    value={firstName}
                    onChange={handleFirstNameChange}
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    className="formal-field"
                    placeholder="Last name"
                    name="lastName"
                    value={lastName}
                    onChange={handleLastNameChange}
                  />
                </div>
                <div className="mb-3">
                  <CardElement
                    className={`formal-field ${
                      cardElementFocused && "is-focused"
                    }`}
                    onFocus={handleCardElementFocus}
                    onBlur={handleCardElementFocus}
                    onChange={handleCardElementChange}
                    options={{
                      style: {
                        base: {
                          fontSize: "16px",
                        },
                        invalid: {
                          color: "#9e2146",
                        },
                      },
                    }}
                  />
                </div>
                <label className="d-flex align-items-center">
                  <input
                    type="checkbox"
                    className="mr-2"
                    onChange={handleTermsCheckboxChange}
                  />{" "}
                  <span className="">
                    You agree to our{" "}
                    <a
                      href="https://foodimus.com/nl/content/terms-condition/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      terms of service
                    </a>
                    .
                  </span>
                </label>
              </div>

              <button
                type="submit"
                className="btn btn--primary btn--rounded btn--block btn--drop-shadow-soft mb-4"
                disabled={!stripe || loading}
              >
                {(loading && `Processing your payment..`) ||
                  settings.buttonText.replace(
                    "{price}",
                    getFormattedValue(prices.setup, locale, currency)
                  )}
              </button>

              {settings.buttonCaption && (
                <div className="mb-3 d-flex align-items-center flex-column">
                  <span className="d-block mb-3 text--caption">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="mr-2 text--purple"
                    />
                    {settings.buttonCaption}
                  </span>
                </div>
              )}

              {settings.terms && <div className="mb-4">{<TrialTerms />}</div>}
            </form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PaymentStep;
