import axios from "axios";

const getFormattedValue = (value = 0, locale = "en-US", currency = "USD") => {
  const formatter = Intl.NumberFormat(locale, {
    style: "currency",
    currency,
    minimumFractionDigits: 0,
    currencyDisplay: "symbol",
    compactDisplay: "short",
  });
  return formatter.format(value);
};

const isAcceptedCurrency = async (currency = "USD") => {
  let accepted = false;
  try {
    const url = process.env.REACT_APP_API_URL + "/payments/accepted-currencies";
    const response = await axios.get(url);
    if (response.status === 200) {
      const acceptedCurrencies = response.data;
      accepted = acceptedCurrencies.includes(currency);
    }
  } catch (err) {
    console.log(err);
  }
  return accepted;
};

const convertToAcceptedCurrency = async (currency) => {
  const defaultCurrency = "USD";
  const isAccepted = await isAcceptedCurrency(currency);
  if (!isAccepted) currency = defaultCurrency;
  return currency;
};

export { getFormattedValue, isAcceptedCurrency, convertToAcceptedCurrency };
