import React, { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import StatsView from "../StatsView";
import LoginView from "../LoginView";
import SessionsView from "../SessionsView";
import { useLoggedIn } from "../../../hooks/useLoggedIn";

const AdminView = () => {
  const { url, path } = useRouteMatch();
  const { loaded, loggedIn } = useLoggedIn();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && loaded) setLoading(false);
    return () => {
      isMounted = false;
    };
  }, [loaded, loggedIn]);

  return (
    <>
      {!loading && !loggedIn && <Redirect to="/admin/login" />}
      <div className="p-4">
        <Nav activeKey="/home">
          <Nav.Item>
            <Nav.Link href={url}>Home</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href={`${url}/sessions`} eventKey="link-1">
              Sessions
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href={`${url}/stats`} eventKey="link-2">
              Stats
            </Nav.Link>
          </Nav.Item>
          {(loggedIn && (
            <Nav.Item>
              <Nav.Link href={`${url}/logout`} eventKey="link-2">
                Logout
              </Nav.Link>
            </Nav.Item>
          )) || (
            <Nav.Item>
              <Nav.Link href={`${url}/login`} eventKey="link-3">
                Login
              </Nav.Link>
            </Nav.Item>
          )}
        </Nav>
      </div>
      <Switch>
        <Route exact path={path}></Route>
        <Route exact path={`${path}/login`} component={LoginView} />
        <Route exact path={`${path}/sessions`} component={SessionsView} />
        <Route exact path={`${path}/stats`} component={StatsView} />
        <Route exact path={`${path}/logout`}>
          {() => localStorage.removeItem("authToken")}
        </Route>
      </Switch>
    </>
  );
};

export default AdminView;
