import { useState, useEffect } from "react";
import axios from "axios";

const useViewData = (slug) => {
  const [viewDataLoaded, setViewDataLoaded] = useState(false);
  const [viewData, setViewData] = useState({
    headline: "",
    subline: "",
    buttonText: "",
  });

  useEffect(() => {
    (async () => {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "/topic/",
        { params: { topic: slug } }
      );
      if (response.status === 200 && response.data.viewData) {
        setViewData(response.data.viewData);
        setViewDataLoaded(true);
      }
    })();
  }, [slug]);

  return { viewData, viewDataLoaded };
};

export default useViewData;
