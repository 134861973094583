import React from "react";
import "./style.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faAppleAlt,
  faCalendarAlt,
  faCalendarCheck,
  faLaughBeam,
} from "@fortawesome/free-solid-svg-icons";

const PlanCreator = ({ name, avatarImage, className }) => {
  return (
    <div className={`plan-creator ${className}`}>
      <div className="plan-creator__background">
        <Row>
          <Col xs={{ span: 8, offset: 2 }}>
            <div className="plan-creator__avatar">
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="plan-creator__avatar-check"
              />
              <div className="plan-creator__avatar-image">
                <img src={avatarImage} alt="Plan creator" />
              </div>
              <footer className="plan-creator__avatar-footer">
                <div className="plan-creator__avatar-name">{name}</div>
              </footer>
            </div>
          </Col>
        </Row>
      </div>
      <div className="mb-4">
        <Row>
          <Col xs={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
            <div className="plan-creator__message">
              Hi, I am here to help you to...
            </div>
          </Col>
        </Row>
      </div>
      <div className="plan-creator__features">
        <Row>
          <Col xs={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
            <Row>
              <Col xs={{ span: 6 }}>
                <div className="plan-creator__feature">
                  <FontAwesomeIcon
                    icon={faLaughBeam}
                    className="plan-creator__feature-icon"
                  />
                  <span className="plan-creator__feature-title">
                    Achieve your
                    <br />
                    goals
                  </span>
                </div>
              </Col>

              <Col xs={{ span: 6 }}>
                <div className="plan-creator__feature">
                  <FontAwesomeIcon
                    icon={faCalendarAlt}
                    className="plan-creator__feature-icon"
                  />
                  <span className="plan-creator__feature-title">
                    Plan your
                    <br />
                    meals
                  </span>
                </div>
              </Col>
              <Col xs={{ span: 6 }}>
                <div className="plan-creator__feature">
                  <FontAwesomeIcon
                    icon={faCalendarCheck}
                    className="plan-creator__feature-icon"
                  />
                  <span className="plan-creator__feature-title">
                    Keep you
                    <br />
                    on track
                  </span>
                </div>
              </Col>
              <Col xs={{ span: 6 }}>
                <div className="plan-creator__feature">
                  <FontAwesomeIcon
                    icon={faAppleAlt}
                    className="plan-creator__feature-icon"
                  />
                  <span className="plan-creator__feature-title">
                    Generate
                    <br />
                    grocery lists
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PlanCreator;
