import React from "react";
import ReactDOM from "react-dom";
import {
  getClientIdFromStorage,
  updateClientIdToStorage,
} from "./util/storage";
import { hotjar } from "react-hotjar";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import App from "./App";

if (process.env.NODE_ENV === "production") {
  hotjar.initialize(process.env.REACT_APP_HOTJAR_ID);
}

if (!getClientIdFromStorage()) {
  const storeClientId = (callback) => {
    if (!window.ga) return;
    let tracker = window.ga.getAll()[0];
    let clientId = tracker.get("clientId");
    if (clientId) {
      updateClientIdToStorage(clientId);
      callback();
    }
  };
  const intv = setInterval(() => {
    storeClientId(() => clearInterval(intv));
  }, 1000);
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
