import axios from "axios";

export const getPrices = async (data) => {
  if (!data.currency || !data.type) return;
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_URL + "/prices",
      {
        params: { currency: data.currency, type: data.type },
      }
    );
    if (response.status === 200) return response.data;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const getPrice = async (data) => {
  if (!data.id || !data.currency || !data.type) return;
  try {
    const response = await axios.get(process.env.REACT_APP_API_URL + "/price", {
      params: { id: data.id, currency: data.currency, type: data.type },
    });
    if (response.status === 200) return response.data;
  } catch (err) {
    console.error(err);
    return false;
  }
};
