import { useState, useContext, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { SessionContext } from "../../context/SessionContext";
import { updateSessionInDb, addSessionEventToDb } from "../../util/sessions";
import { isValidEmail } from "../../util/validation";
import gaAnalytics from "../../util/ga";
import { ExperimentContext } from "../../context/ExperimentContext";
import { APP_NAME } from "../../constants";
import { Row, Col } from "react-bootstrap";

import productPreviewImage from "../../images/product-preview-transparent.png";

const RegistrationStep = ({ baseUrl }) => {
  const [settings, setSettings] = useState({
    visual: false,
    stepProgress: "Step 1 of 3",
    headline: "Leave your email to start your trial",
    subline:
      "Just a few more steps and you're finished! We hate paperwork, too.",
    nameField: false,
    termsField: false,
    buttonText: "Continue",
    nextUrl: `${baseUrl}/signup/choose-trial`,
  });

  const { topicSlug } = useParams();
  const history = useHistory();
  const [session, setSession] = useContext(SessionContext);
  const { sessionId, email, name } = session;
  const [experiment] = useContext(ExperimentContext);
  const [errorAlert, setErrorAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);

  useEffect(() => {
    document.title = `Registration - ${APP_NAME}`;
  }, []);

  useEffect(() => {
    if (!experiment.fv) return;
    switch (experiment.fv) {
      case 2:
        setSettings((prevState) => ({
          ...prevState,
          visual: true,
          stepProgress: false,
          headline: "Finish setting up your account",
          subline: false,
          nameField: true,
          termsField: true,
        }));
        break;
      default:
    }
  }, [experiment]);

  const handleContinueButtonClick = async (e) => {
    e.preventDefault();
    let error = false;
    setLoading(true);

    if (!email || email.length === 0 || !isValidEmail(email)) {
      error = true;
      setErrorAlert("Please enter a valid email address.");
    } else if (settings.nameField && (!name || name.length === 0)) {
      error = true;
      setErrorAlert("Please enter your name.");
    } else if (settings.termsField && !termsChecked) {
      error = true;
      setErrorAlert("Please accept the terms of service.");
    } else {
      setErrorAlert(false);
    }

    if (!error) {
      setLoading(false);
      gaAnalytics.sendEvent({
        category: "Wizard",
        action: "Click",
        label: "Signup button",
      });
      await updateSessionInDb(sessionId, "email", email);
      await addSessionEventToDb(sessionId, "signupRegisterButtonClick");
      if (settings.termsField)
        await addSessionEventToDb(sessionId, "acceptTerms");

      continueNext();
    }
  };

  const continueNext = () => {
    history.push(settings.nextUrl);
  };

  const handleEmailChange = (e) => {
    setLoading(false);
    setErrorAlert(false);
    setSession({ ...session, email: e.target.value });
  };

  const handleNameChange = (e) => {
    setLoading(false);
    setErrorAlert(false);
    setSession({ ...session, name: e.target.value });
  };

  const handleTermsCheckboxChange = (event) => {
    setLoading(false);
    setErrorAlert(false);
    if (event.target.checked) setTermsChecked(true);
    else setTermsChecked(false);
  };

  return (
    <form>
      <Row>
        <Col xs={{ span: 10, offset: 1 }} md={{ span: 8, offset: 2 }}>
          <header className="mb-4 text-center">
            {settings.visual && (
              <div className="mb-3">
                <img
                  src={productPreviewImage}
                  alt="Product preview"
                  width="100%"
                />
              </div>
            )}
            {settings.stepProgress && (
              <span className="text-uppercase d-block mb-2">
                {settings.stepProgress}
              </span>
            )}
            {settings.headline && <h3 className="mb-3">{settings.headline}</h3>}
            {settings.subline && <p>{settings.subline}</p>}
          </header>

          {errorAlert && (
            <div className="mb-3">
              <p className="alert alert-danger">{errorAlert}</p>
            </div>
          )}
          {settings.nameField && (
            <div className="mb-2">
              <input
                type="text"
                className="formal-field"
                placeholder="Your name"
                onChange={handleNameChange}
                value={name || ""}
                autoComplete="false"
              />
            </div>
          )}
          <div className={settings.termsField ? "mb-3" : "mb-4"}>
            <input
              type="email"
              className="formal-field"
              placeholder="Your email address"
              onChange={handleEmailChange}
              value={email || ""}
              autoComplete="false"
            />
          </div>
          {settings.termsField && (
            <div className="mb-4">
              <label className="d-flex align-items-center">
                <input
                  type="checkbox"
                  className="mr-2"
                  onChange={handleTermsCheckboxChange}
                />{" "}
                <span className="">
                  You agree to our{" "}
                  <a
                    href="https://foodimus.com/nl/content/terms-condition/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    terms of service
                  </a>
                  .
                </span>
              </label>
            </div>
          )}
          <button
            className="btn btn--primary btn--block btn--rounded btn--drop-shadow-soft"
            onClick={handleContinueButtonClick}
            disabled={loading || errorAlert}
          >
            {settings.buttonText}
          </button>
        </Col>
      </Row>
    </form>
  );
};

export default RegistrationStep;
