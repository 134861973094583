import React from "react";
import "./style.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "../../images/logo-white.svg";

const SimpleHeader = ({ floating, className, transparent }) => {
  let classes = (className && className.split(" ")) || [];
  classes.push("simple-header");
  if (floating) classes.push("simple-header--floating");
  if (transparent) classes.push("simple-header--transparent");

  return (
    <div className={classes.join(" ")}>
      <Container>
        <Row>
          <Col md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
            <div className="d-flex justify-content-between">
              <div className="simple-header__logo">
                <img src={logo} alt="logo" />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SimpleHeader;
