import React, { useState } from "react";
import "./style.scss";
import { getConfigFromStorage } from "../../util/storage";
import ChoicesField from "../ChoicesField";
import maleEctomorph from "../../images/male-ectomorph.svg";
import maleMesomorph from "../../images/male-mesomorph.svg";
import maleEndomorph from "../../images/male-endomorph.svg";
import femaleEctomorph from "../../images/female-ectomorph.svg";
import femaleMesomorph from "../../images/female-mesomorph.svg";
import femaleEndomorph from "../../images/female-endomorph.svg";

const BodyTypeField = ({ id, input, handleChange }) => {
  const config = getConfigFromStorage();
  const gender =
    (config.gender && config.gender.gender && config.gender.gender) || "male";
  let [options] = useState([
    {
      id: "ectomorph",
      title: "Ectomorph",
      value: "ectomorph",
      image: gender === "male" ? maleEctomorph : femaleEctomorph,
    },
    {
      id: "mesomorph",
      title: "Mesomorph",
      value: "mesomorph",
      image: gender === "male" ? maleMesomorph : femaleMesomorph,
    },
    {
      id: "endomorph",
      title: "Endomorph",
      value: "endomorph",
      image: gender === "male" ? maleEndomorph : femaleEndomorph,
    },
  ]);

  return (
    <>
      <ChoicesField
        options={options}
        input={input}
        columns="3"
        id={id}
        handleChange={handleChange}
      ></ChoicesField>
    </>
  );
};

export default BodyTypeField;
