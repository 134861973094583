import React, { useState } from "react";
import { useHistory, Redirect } from "react-router-dom";
import { useLoggedIn } from "../../../hooks/useLoggedIn";
import { signAdminIn } from "../../../util/auth";
import { Container, Alert } from "react-bootstrap";

const LoginView = () => {
  const history = useHistory();
  const { loggedIn } = useLoggedIn();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorAlert, setErrorAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (email.length === 0) return;
    if (password.length === 0) return;
    setLoading(true);
    const response = await signAdminIn(email, password);
    if (response) {
      if (response.error) {
        setErrorAlert(response.error.message);
      } else {
        history.push("/admin");
      }
    }
    setLoading(false);
  };

  return (
    <div>
      {loggedIn && <Redirect to="/admin" />}
      <Container>
        <header className="mb-5">
          <h1>Login</h1>
        </header>
        {errorAlert && <Alert variant="danger">{errorAlert}</Alert>}
        <form method="post" onSubmit={handleFormSubmit}>
          <div className="mb-4">
            <input
              type="text"
              className="form-control"
              placeholder="Username"
              onChange={handleEmailChange}
            />
          </div>
          <div className="mb-4">
            <input
              type="password"
              className="form-control"
              placeholder="Password"
              onChange={handlePasswordChange}
            />
          </div>
          <div className="mb-4">
            <button className="btn btn-primary">
              {(loading && "Loading") || "Login"}
            </button>
          </div>
        </form>
      </Container>
    </div>
  );
};

export default LoginView;
