import React, { useState } from "react";
import "./style.scss";

const UnitField = ({ id, input, unit, handleChange }) => {
  const [value, setValue] = useState(input[id] || "");

  const handleBlur = (e) => {
    handleChange(e.target.name, e.target.value);
    setValue(e.target.value);
  };

  return (
    <div className="unit-field">
      <input
        type="number"
        className="unit-field__field"
        onChange={handleBlur}
        value={value}
        name={id}
      />
      <div className="unit-field__unit">{unit}</div>
    </div>
  );
};

export default UnitField;
