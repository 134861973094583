import axios from "axios";

export const getTopicMealPlanId = async (slug) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_URL + "/topic/",
      { params: { topic: slug } }
    );
    console.log(response.data);
    if (response.status === 200 && response.data.mealPlanId) {
      return response.data.mealPlanId;
    } else {
      return false;
    }
  } catch (err) {
    console.error(err);
  }
};
