import React, { useState, createContext, useEffect, useContext } from "react";
import {
  updateConfigToStorage,
  updateUserEmailToStorage,
  updateSessionIdToStorage,
  getConfigFromStorage,
  getUserEmailFromStorage,
  getSessionIdFromStorage,
  getNameFromStorage,
  updateNameToStorage,
} from "../util/storage";
import { addSessionMetadataToDb } from "../util/sessions";
import { PreferenceContext } from "./PreferenceContext";
import { ExperimentContext } from "./ExperimentContext";
import { getPrice } from "../util/prices";

export const SessionContext = createContext();

export const SessionProvider = (props) => {
  const [preferences] = useContext(PreferenceContext);
  const [experiment] = useContext(ExperimentContext);
  const { currency } = preferences;
  const [session, setSession] = useState({
    config: getConfigFromStorage() || {},
    email: getUserEmailFromStorage() || null,
    name: getNameFromStorage() || null,
    sessionId: getSessionIdFromStorage() || null,
    setupType: localStorage.getItem("setupType"),
    setupPrice: localStorage.getItem("setupPrice"),
    totalPrice: localStorage.getItem("totalPrice"),
    paymentRequired: true,
  });

  useEffect(() => {
    if (!experiment.fv) return;
    switch (experiment.fv) {
      case 1:
        setSession((prevState) => ({
          ...prevState,
          setupType: "trial",
        }));
        break;
      case 2:
        setSession((prevState) => ({
          ...prevState,
          setupType: "contribution",
        }));
        break;
      default:
    }
  }, [experiment.fv]);

  useEffect(() => {
    if (!experiment.pv) return;
    setSession((prevState) => ({
      ...prevState,
      totalPrice: experiment.pv,
    }));
  }, [experiment.pv]);

  useEffect(() => {
    updateSessionIdToStorage(session.sessionId);
  }, [session.sessionId]);

  useEffect(() => {
    updateConfigToStorage(session.config);
  }, [session.config]);

  useEffect(() => {
    updateUserEmailToStorage(session.email);
  }, [session.email]);

  useEffect(() => {
    updateNameToStorage(session.name);
  }, [session.name]);

  useEffect(() => {
    (async () => {
      if (session.setupType) {
        localStorage.setItem("setupType", session.setupType);
        await addSessionMetadataToDb(
          session.sessionId,
          "setupType",
          session.setupType
        );
      }
    })();
  }, [session.setupType, session.sessionId]);

  useEffect(() => {
    (async () => {
      if (session.setupPrice) {
        localStorage.setItem("setupPrice", session.setupPrice);
        await addSessionMetadataToDb(
          session.sessionId,
          "setupPrice",
          session.setupPrice
        );
      }
    })();
  }, [session.setupPrice, session.sessionId]);

  useEffect(() => {
    (async () => {
      if (session.totalPrice) {
        localStorage.setItem("totalPrice", session.totalPrice);
        await addSessionMetadataToDb(
          session.sessionId,
          "totalPrice",
          session.totalPrice
        );
      }
    })();
  }, [session.totalPrice, session.sessionId]);

  useEffect(() => {
    (async () => {
      let currenctSetupPriceObj = await getPrice({
        currency,
        type: session.setupType,
        id: session.setupPrice,
      });
      if (currenctSetupPriceObj) {
        if (parseInt(currenctSetupPriceObj.price) === 0) {
          setSession((prevState) => ({ ...prevState, paymentRequired: false }));
        } else {
          setSession((prevState) => ({ ...prevState, paymentRequired: true }));
        }
      }
    })();
  }, [session.setupType, session.setupPrice]);

  return (
    <SessionContext.Provider value={[session, setSession]}>
      {props.children}
    </SessionContext.Provider>
  );
};
