import { useState, useEffect } from "react";
import { verifyAuthToken } from "../util/auth";

export const useLoggedIn = () => {
  const [authToken] = useState(localStorage.getItem("authToken"));
  const [loaded, setLoaded] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    if (authToken) {
      (async () => {
        const verified = await verifyAuthToken(authToken);
        if (verified) {
          setLoaded(true);
          setLoggedIn(true);
        }
      })();
    } else {
      setLoaded(true);
    }
  }, [authToken]);

  return { loggedIn, loaded };
};
