import React, { useEffect, useState, useContext } from "react";
import "./style.css";
import { getUnitTypeData } from "../../util/utils";
import UnitField from "../UnitField";
import { PreferenceContext } from "../../context/PreferenceContext";

const HeightField = ({ id, input, handleChange }) => {
  const [preferences] = useContext(PreferenceContext);
  const { unitType } = preferences;
  const [unit, setUnit] = useState();

  useEffect(() => {
    const unitTypeData = getUnitTypeData(unitType);
    setUnit(unitTypeData.heightUnit);
  }, [unitType]);

  return (
    <>
      <UnitField
        id={id}
        input={input}
        unit={unit}
        handleChange={handleChange}
      />
    </>
  );
};

export default HeightField;
