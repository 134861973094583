import React, { useContext } from "react";
import { getFormattedValue } from "../../util/money";
import { PreferenceContext } from "../../context/PreferenceContext";

const Price = ({ value }) => {
  const [preferences] = useContext(PreferenceContext);
  const { locale, currency } = preferences;

  return (
    <span className="price">{getFormattedValue(value, locale, currency)}</span>
  );
};

export default Price;
