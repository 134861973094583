import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import useViewData from "../../hooks/useViewData";
import { Survey } from "../../util/survey";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "../../images/logo-white.svg";

const HomeView = () => {
  const history = useHistory();
  const { topicSlug } = useParams();
  const survey = Survey({ topicSlug, history });
  const { navigateToFirstStep } = survey;
  const [loading, setLoading] = useState(false);
  const { viewData, viewDataLoaded } = useViewData(topicSlug);
  const [headline, setHeadline] = useState();
  const [subline, setSubline] = useState();
  const [buttonText, setButtonText] = useState();
  const [heroBackground, setHeroBackground] = useState();

  const handleStartButtonClick = () => {
    navigateToFirstStep();
  };

  useEffect(() => {
    if (viewData.headline) setHeadline(viewData.headline);
    if (viewData.subline) setSubline(viewData.subline);
    if (viewData.buttonText) setButtonText(viewData.buttonText);
    if (viewData.heroImage) setHeroBackground(viewData.heroImage);
  }, [viewDataLoaded, viewData]);

  return (
    <>
      {!loading && (
        <div>
          <div
            className="hero hero--full"
            style={{ backgroundImage: `url(${heroBackground})` }}
          >
            <div className="simple-header simple-header--transparent">
              <Container>
                <Row>
                  <Col md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
                    <div className="d-flex justify-content-between">
                      <div className="simple-header__logo">
                        <img src={logo} alt="logo" />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <Container>
              <Row>
                <Col md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
                  <div className="hero__inner">
                    <header className="mb-4">
                      <h1 className="hero__headline hero__headline--large mb-3">
                        {headline}
                      </h1>
                      <p className="hero__subline">{subline}</p>
                    </header>
                    <button
                      className="btn btn--pink btn--drop-shadow btn--large"
                      onClick={handleStartButtonClick}
                    >
                      {buttonText}
                    </button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <footer className="app-footer pt-3">
            <Container>
              <Row>
                <Col md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
                  <Row>
                    <Col xs="12" md="6">
                      <div className="app-footer__copyright text-center text-md-left mb-2">
                        <span>
                          Copyright &copy; Foodimus. All Rights Reserved
                        </span>
                      </div>
                    </Col>
                    <Col xs="12" md="6">
                      <div className="d-flex justify-content-center justify-content-md-end mb-2">
                        <ul className="app-footer__list">
                          <li className="app-footer__list-item">
                            <a
                              href="https://www.foodimus.com/content/privacy-policy/"
                              target="_blank"
                              rel="noreferrer"
                              className="app-footer__list-link"
                            >
                              Privacy policy
                            </a>
                          </li>
                          <li className="app-footer__list-item">
                            <a
                              href="https://www.foodimus.com/content/terms-condition/"
                              target="_blank"
                              rel="noreferrer"
                              className="app-footer__list-link"
                            >
                              Terms & Conditions
                            </a>
                          </li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </footer>
        </div>
      )}
    </>
  );
};

export default HomeView;
