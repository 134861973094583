import React from "react";
import { getUnitTypeFromStorage } from "../../util/storage";
import { getUnitTypeData } from "../../util/utils";
import moment from "moment";

const ProgressChart = ({ startDate, startWeight, targetWeight }) => {
  const unitType = getUnitTypeFromStorage();
  const unitTypeData = getUnitTypeData(unitType);
  const weightUnit = unitTypeData.weightUnit;
  const weightDifference = Math.abs(startWeight - targetWeight);

  let progressWeightOne;
  let progressWeightTwo;

  if (startWeight > targetWeight) {
    progressWeightOne = parseInt(startWeight) - weightDifference * 0.3;
    progressWeightTwo = parseInt(startWeight) - weightDifference * 0.8;
  } else {
    progressWeightOne = parseInt(startWeight) + weightDifference * 0.3;
    progressWeightTwo = parseInt(startWeight) + weightDifference * 0.8;
  }

  const getConvertedWeight = (weight) => {
    return Math.round(weight);
  };

  const getWeightWithUnit = (weight) => {
    return getConvertedWeight(weight) + weightUnit;
  };

  return (
    <svg
      width="100%"
      viewBox="0 0 512 274"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient
          x1="0%"
          y1="100%"
          x2="100%"
          y2="100%"
          id="linearGradient-1"
        >
          <stop stopColor="#FCD779" offset="0%"></stop>
          <stop stopColor="#F8B544" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Personal-plan" transform="translate(-63.000000, -962.000000)">
          <g id="Visual" transform="translate(64.000000, 957.000000)">
            <g
              id="Lines"
              transform="translate(0.000000, 68.000000)"
              stroke="#C5C5C5"
              strokeLinecap="square"
            >
              <line
                x1="0.5"
                y1="0.570652174"
                x2="0.5"
                y2="68.5"
                id="Line-2"
              ></line>
              <line
                x1="145.5"
                y1="0.570652174"
                x2="145.5"
                y2="68.5"
                id="Line-2-Copy-2"
                strokeDasharray="4"
              ></line>
              <line
                x1="508.5"
                y1="0.570652174"
                x2="508.5"
                y2="68.5"
                id="Line-2-Copy-5"
                strokeDasharray="4"
              ></line>
              <line
                x1="435.5"
                y1="0.570652174"
                x2="435.5"
                y2="68.5"
                id="Line-2-Copy-4"
                strokeDasharray="4"
              ></line>
              <line
                x1="363.5"
                y1="1.03532609"
                x2="363.5"
                y2="68.9646739"
                id="Line-2-Copy-6"
                strokeDasharray="4"
              ></line>
              <line
                x1="290.5"
                y1="0.570652174"
                x2="290.5"
                y2="68.5"
                id="Line-2-Copy-3"
                strokeDasharray="4"
              ></line>
              <line
                x1="218.5"
                y1="1.03532609"
                x2="218.5"
                y2="68.9646739"
                id="Line-2-Copy-7"
                strokeDasharray="4"
              ></line>
            </g>
            <path
              d="M0,135.841292 C53.8473766,135.841292 89.3252287,133.445226 106.433556,128.653092 C116.193948,125.919154 147.291172,108.147486 186.798218,107.999756 C202.332941,107.942007 219.51041,118.219053 236.685291,121.718553 C243.603148,123.128114 266.743134,132.087656 305.288266,135.841292 C323.855841,137.649457 345.331134,134.667007 371.553959,135.841292 C396.720035,136.968255 442.678771,136.968255 509.430168,135.841292 L510,279 L0,279 L0,135.841292 Z"
              id="Path-6"
              fill="#FCEBC1"
            ></path>
            <path
              d="M1,136.141319 C54.6962062,136.141319 90.0744582,133.707097 107.134756,128.838654 C116.543253,126.153781 149.244757,108.693447 186.798218,108 C203.36925,107.694006 218.442036,117.937027 237.020824,121.79369 C243.91926,123.225697 266.994283,132.32791 305.431204,136.141319 C323.946652,137.978276 345.361656,134.948334 371.510863,136.141319 C396.606289,137.286227 442.436001,137.286227 509,136.141319"
              id="Path-6"
              stroke="url(#linearGradient-1)"
              strokeWidth="4"
              strokeLinecap="round"
            ></path>
            <g
              id="Points"
              transform="translate(63.000000, 102.000000)"
              stroke="#FFFFFF"
              strokeWidth="3"
            >
              <circle id="Point" fill="#FA7B67" cx="11" cy="34" r="11"></circle>
              <circle id="Point" fill="#FA7B67" cx="83" cy="11" r="11"></circle>
              <circle
                id="Point"
                fill="#FA7B67"
                cx="155"
                cy="15"
                r="11"
              ></circle>
              <circle
                id="Point"
                fill="#FA7B67"
                cx="227"
                cy="31"
                r="11"
              ></circle>
              <circle
                id="Point"
                fill="#5952C1"
                cx="301"
                cy="34"
                r="11"
              ></circle>
              <circle
                id="Point"
                fill="#5952C1"
                cx="373"
                cy="34"
                r="11"
              ></circle>
            </g>
            <text fontSize="24" fontWeight="normal" fill="#686868">
              <tspan x="54.3339844" y="23">
                {moment(startDate).format("MMM")}
              </tspan>
            </text>
            <text fontSize="22" fontWeight="normal" fill="#2C2C2C">
              <tspan x="50.1469727" y="113">
                {getWeightWithUnit(startWeight)}
              </tspan>
            </text>
            <text fontSize="22" fontWeight="normal" fill="#2C2C2C">
              <tspan x="124.646973" y="154">
                {getWeightWithUnit(progressWeightOne)}
              </tspan>
            </text>
            <text fontSize="22" fontWeight="normal" fill="#2C2C2C">
              <tspan x="191.646973" y="94">
                {getWeightWithUnit(progressWeightTwo)}
              </tspan>
            </text>
            <text fontSize="22" fontWeight="normal" fill="#FA7B67">
              <tspan x="263.646973" y="171">
                {getWeightWithUnit(targetWeight)}
              </tspan>
            </text>
            <text
              id="Keep-it-off"
              fontSize="20"
              fontWeight="normal"
              fill="#5952C1"
            >
              <tspan x="375.381836" y="109">
                KEEP IT OFF
              </tspan>
            </text>
            <text fontSize="24" fontWeight="normal" fill="#686868">
              <tspan x="129.326172" y="23">
                {moment(startDate).add(1, "month").format("MMM")}
              </tspan>
            </text>
            <text fontSize="24" fontWeight="normal" fill="#686868">
              <tspan x="198.330078" y="23">
                {moment(startDate).add(2, "month").format("MMM")}
              </tspan>
            </text>
            <text fontSize="24" fontWeight="normal" fill="#686868">
              <tspan x="273.652344" y="23">
                {moment(startDate).add(3, "month").format("MMM")}
              </tspan>
            </text>
            <text fontSize="24" fontWeight="normal" fill="#686868">
              <tspan x="350.660156" y="23">
                {moment(startDate).add(4, "month").format("MMM")}
              </tspan>
            </text>
            <text fontSize="24" fontWeight="normal" fill="#686868">
              <tspan x="417.648438" y="23">
                {moment(startDate).add(5, "month").format("MMM")}
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ProgressChart;
