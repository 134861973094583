import React, { useEffect, useState } from "react";
import { Container, Table, Badge } from "react-bootstrap";
import { getSessionsFromDb } from "../../../util/sessions";

const SessionsView = () => {
  const [sessions, setSessions] = useState([]);

  useEffect(() => {
    (async () => {
      const s = await getSessionsFromDb();
      setSessions(s);
    })();
  }, []);

  return (
    <div className="p-4">
      <Container fluid>
        <header className="mb-4">
          <h1>Sessions</h1>
        </header>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Email</th>
              <th>Country</th>
              <th>Currency</th>
              <th>Locale</th>
              <th>UnitType</th>
              <th>Topic</th>
              <th>Client ID</th>
              <th>Setup price</th>
              <th>Total price</th>
              <th>PBC</th>
              <th>Purchased</th>
              <th>Date</th>
              <th>#</th>
            </tr>
          </thead>
          <tbody>
            {sessions &&
              sessions.map((session, i) => (
                <tr key={i}>
                  <td>{session.email || "-"}</td>
                  <td>{session.settings.countryCode || ""}</td>
                  <td>{session.settings.currency || ""}</td>
                  <td>{session.settings.locale || ""}</td>
                  <td>{session.settings.unitType || ""}</td>
                  <td>{(session.metadata && session.metadata.topic) || "-"}</td>
                  <td>
                    {(session.metadata && session.metadata.gaClientId) || "-"}
                  </td>
                  <td>
                    {(session.metadata && session.metadata.setupPrice) || "-"}
                  </td>
                  <td>
                    {(session.metadata && session.metadata.totalPrice) || "-"}
                  </td>
                  <td>
                    {(session.events && session.events.purchaseButtonClick && (
                      <Badge variant="success">Yes</Badge>
                    )) || <Badge variant="danger">No</Badge>}
                  </td>
                  <td>
                    {(session.purchase && (
                      <Badge variant="success">Yes</Badge>
                    )) || <Badge variant="danger">No</Badge>}
                  </td>
                  <td>
                    {(session.date &&
                      new Date(session.date).toLocaleString()) ||
                      ""}
                  </td>
                  <td>{session.id}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Container>
    </div>
  );
};

export default SessionsView;
