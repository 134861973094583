import { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import SurveyView from "../../views/SurveyView";
import HomeView from "../../views/HomeView";
import TopicView from "../../views/TopicView";
import ProcessingView from "../../views/ProcessingView";
import PersonalPlanView from "../../views/PersonalPlanView";
import SignupView from "../../views/SignupView";
import AdminView from "../../views/admin/AdminView";
import PaymentSuccessView from "../../views/PaymentSuccessView";

import { ExperimentProvider } from "../../context/ExperimentContext";
import { SessionProvider } from "../../context/SessionContext";
import { PreferenceProvider } from "../../context/PreferenceContext";

import useGoogleAnalytics from "../../hooks/useGoogleAnalytics";
import useFacebookPixel from "../../hooks/useFacebookPixel";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Routes = () => {
  const query = useQuery();
  useGoogleAnalytics();
  useFacebookPixel();

  useEffect(() => {
    if (query.get("empty_storage")) {
      localStorage.clear();
      window.location.replace("/");
    }
  }, []);

  return (
    <ExperimentProvider>
      <Elements stripe={stripePromise}>
        <PreferenceProvider>
          <SessionProvider>
            <Switch>
              <Route exact path="/" component={HomeView} />
              <Route exact path="/topic/:topicSlug" component={TopicView} />
              <Route
                exact
                path={["/step/:stepSlug", "/topic/:topicSlug/step/:stepSlug"]}
                component={SurveyView}
              />
              <Route
                exact
                path={["/processing", "/topic/:topicSlug/processing"]}
                component={ProcessingView}
              />
              <Route
                exact
                path={["/personal-plan", "/topic/:topicSlug/personal-plan"]}
                component={PersonalPlanView}
              ></Route>
              <Route
                exact
                path={["/signup/:step", "/topic/:topicSlug/signup/:step"]}
                component={SignupView}
              />
              <Route
                exact
                path="/payment-success"
                component={PaymentSuccessView}
              />
              <Route exact path="/start">
                <Redirect to="/step/gender" />
              </Route>
              <Route path="/admin" component={AdminView}></Route>
            </Switch>
          </SessionProvider>
        </PreferenceProvider>
      </Elements>
    </ExperimentProvider>
  );
};

export default Routes;
