import React, { useState, createContext, useEffect } from "react";
import {
  getUnitTypeFromStorage,
  updateUnitTypeToStorage,
  getCurrencyFromStorage,
  updateCurrencyToStorage,
  getCountryCodeFromStorage,
  updateCountryCodeToStorage,
  getLocaleFromStorage,
  updateLocaleToStorage,
} from "../util/storage";
import { convertToAcceptedCurrency } from "../util/money";
import {
  getClientCountryCode,
  getClientCurrency,
  getClientLocale,
} from "../util/client";

export const PreferenceContext = createContext();

export const PreferenceProvider = (props) => {
  const [preferences, setPreferences] = useState({
    countryCode: getCountryCodeFromStorage() || false,
    currency: getCurrencyFromStorage() || false,
    locale: getLocaleFromStorage() || false,
    unitType: getUnitTypeFromStorage() || "imperial",
  });
  const { countryCode, locale, currency, unitType } = preferences;

  useEffect(() => {
    (async () => {
      if (!countryCode || !locale) {
        try {
          let code = await getClientCountryCode();
          let loc = getClientLocale();
          let cur = getClientCurrency(code);
          setPreferences((prevState) => ({
            ...prevState,
            countryCode: code,
            locale: loc.replace("_", "-"),
            currency: cur,
          }));
        } catch (err) {
          console.error(err);
        }
      }
    })();
  }, []);

  useEffect(() => {
    updateCountryCodeToStorage(countryCode);
  }, [countryCode]);

  useEffect(() => {
    updateLocaleToStorage(locale);
  }, [locale]);

  useEffect(() => {
    updateUnitTypeToStorage(unitType);
  }, [unitType]);

  useEffect(() => {
    const updateCurrencyEffect = async () => {
      let updatedCurrency = await convertToAcceptedCurrency(currency);
      updateCurrencyToStorage(updatedCurrency);
    };
    updateCurrencyEffect();
  }, [currency]);

  return (
    <PreferenceContext.Provider value={[preferences, setPreferences]}>
      {props.children}
    </PreferenceContext.Provider>
  );
};
