export const getUnitTypeData = (unitType) => {
  let data = {};
  if (unitType) {
    if ("imperial" === unitType) {
      data.weightUnit = "lbs";
      data.heightUnit = "ft";
    } else {
      data.weightUnit = "kg";
      data.heightUnit = "cm";
    }
  }
  return data;
};
