import ReactGA from "react-ga";

const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;

const init = () => {
  // Enable debug mode on the local development environment
  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";
  // ReactGA.initialize(TRACKING_ID, { debug: isDev });
  ReactGA.initialize(TRACKING_ID);
};

const sendEvent = (payload) => {
  ReactGA.event(payload);
};

const sendPageview = (path) => {
  ReactGA.set({ page: path });
  ReactGA.pageview(path);
};

const gaAnalytics = { init, sendEvent, sendPageview };

export default gaAnalytics;
