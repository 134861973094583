import React, { useEffect } from "react";
import "./style.scss";
import { eventToDatalayer } from "../../util/tracking";
import { APP_NAME } from "../../constants";
import { Container, Row, Col } from "react-bootstrap";
import SimpleHeader from "../../components/SimpleHeader";
import PlanCreator from "../../components/PlanCreator";
import ConfettiGenerator from "confetti-js";
import avatarImage from "../../images/tessa.jpeg";

const PaymentSuccessView = () => {
  useEffect(() => {
    document.title = `Welcome! - ${APP_NAME}`;
    eventToDatalayer("optimize.activate");
  }, []);

  useEffect(() => {
    const confettiSettings = { target: "confetti" };
    const confetti = new ConfettiGenerator(confettiSettings);
    confetti.render();
    return () => confetti.clear();
  }, []);

  return (
    <div>
      <canvas id="confetti" className="confetti-bg"></canvas>
      <SimpleHeader className="mb-5" />
      <Container>
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <div className="d-flex flex-column justify-content-center">
              <header className="mb-3 text-center">
                <h2 className="mb-3">
                  Within 24 hours your personal plan is ready!
                </h2>
                <p>
                  Based on your responses, our nutritional consultant will
                  immediately start putting together your plan. When your plan
                  is ready <strong>you will receive an email</strong> to your
                  personal dashboard.
                </p>
              </header>
              <div className="mb-5">
                <header className="text-center mb-4">
                  <h4>
                    Meet the <span className="text--purple">creator</span> of
                    your
                    <br />
                    personalized plan
                  </h4>
                </header>
                <PlanCreator name="Tessa" avatarImage={avatarImage} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PaymentSuccessView;
