import React, { useState, useContext } from "react";
import Switcher from "../Switcher";
import { PreferenceContext } from "../../context/PreferenceContext";
import "./style.scss";

const UnitSwitcher = ({ className }) => {
  const [preferences, setPreference] = useContext(PreferenceContext);
  const { unitType } = preferences;
  const [options] = useState([
    {
      id: "imperial",
      title: "Imperial",
    },
    {
      id: "metric",
      title: "Metric",
    },
  ]);

  const handleSwitch = (optionId) => {
    setPreference((prevState) => ({ ...prevState, unitType: optionId }));
  };

  return (
    <Switcher
      className={className}
      options={options}
      handleSwitch={handleSwitch}
      activeOptionId={unitType}
    ></Switcher>
  );
};

export default UnitSwitcher;
