import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import PlanCreator from "../../components/PlanCreator";
import ProgressChart from "../../components/ProgressChart";
import SimpleHeader from "../../components/SimpleHeader";
import Timer from "../../components/Timer";
import useCreator from "../../hooks/useCreator";
import { ExperimentContext } from "../../context/ExperimentContext";
import { SessionContext } from "../../context/SessionContext";
import productPreviewImage from "../../images/product-preview.png";
import recipeImage1 from "../../images/recipe-1.jpg";
import recipeImage2 from "../../images/recipe-2.jpg";
import recipeImage3 from "../../images/recipe-3.jpg";
import recipeImage4 from "../../images/recipe-4.jpg";
import avatarImage from "../../images/tessa.jpeg";
import trophyIcon from "../../images/trophy-icon.svg";
import gaAnalytics from "../../util/ga";
import {
  addSessionEventToDb,
  addSessionMetadataToDb,
  storeSessionToDb,
} from "../../util/sessions";

import {
  getConfigFromStorage,
  getUnitTypeFromStorage,
  updateSessionIdToStorage,
  updateOfferEndTimeToStorage,
} from "../../util/storage";
import { getUnitTypeData } from "../../util/utils";
import moment from "moment";

const PersonalPlanView = () => {
  const [settings, setSettings] = useState({
    buttonCaption: "No commitment - cancel any time",
    buttonText: "Setup my account",
    nextUrl: "/signup/start",
  });

  const { topicSlug } = useParams();
  const history = useHistory();
  const [session, setSession] = useContext(SessionContext);
  const [loading, setLoading] = useState(false);
  const [experiment] = useContext(ExperimentContext);
  const creator = useCreator(topicSlug);

  const unitType = getUnitTypeFromStorage();
  const unitTypeData = getUnitTypeData(unitType);
  const weightUnit = unitTypeData.weightUnit;
  const startWeight = parseInt(getConfigFromStorage("measurements", "weight"));
  const targetWeight = parseInt(
    getConfigFromStorage("idealWeight", "idealWeight")
  );
  const weightDifference = Math.abs(startWeight - targetWeight);
  const loseOrGain = startWeight > targetWeight ? "Lose" : "Gain";

  // Offer
  const minutesLasting = 15;
  const [offerEndTime, setOfferEndTime] = useState(
    localStorage.getItem("offerEndTime")
  );
  const [offerEnded, setOfferEnded] = useState(false);

  const offerEndedTimerCallback = () => {
    setOfferEnded(true);
  };

  const continueToNext = () => {
    history.push(settings.nextUrl);
  };

  const handleContinueButtonClick = async () => {
    setLoading(true);
    const sessionId = await storeSessionToDb();
    if (sessionId) {
      updateSessionIdToStorage(sessionId);
      setSession({ ...session, sessionId });
      gaAnalytics.sendEvent({
        category: "Wizard",
        action: "Click",
        label: "Claim offer",
      });
      addSessionEventToDb(sessionId, "claimOfferButtonClick");
      if (topicSlug) addSessionMetadataToDb(sessionId, "topic", topicSlug);
      setLoading(false);
      continueToNext();
    }
  };

  useEffect(() => {
    if (!experiment.flow) return;
    switch (experiment.flow) {
      case 2:
        setSettings((prevState) => ({
          ...prevState,
          nextUrl: "/signup/registration",
          buttonCaption: "No payment needed. Foodimus is a free service.",
        }));
        break;
      default:
    }
  }, [experiment]);

  useEffect(() => {
    let endTime = moment().add(minutesLasting, "minutes");
    if (!offerEndTime) {
      updateOfferEndTimeToStorage(endTime);
      setOfferEndTime(endTime);
    }
  }, [history, offerEndTime, setOfferEndTime]);

  return (
    <div>
      <SimpleHeader floating="true" />
      <div className="hero">
        <Container>
          <div className="hero__inner hero__inner--bottom">
            <Row>
              <Col md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
                <h1 className="hero__headline">
                  Your <br /> Personalized Plan
                </h1>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Container>
        <Row>
          <Col md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
            <div className="hero__overlap mb-1">
              <div className="icon-text">
                <div className="icon-text__image">
                  <img src={trophyIcon} alt="trophy" />
                </div>
                <div className="icon-text__text">
                  <p>
                    Become the person you inspire to be. {loseOrGain}{" "}
                    {Math.round(weightDifference)}
                    {weightUnit} by {moment().add(3, "month").format("MMMM")}.
                  </p>
                </div>
              </div>
            </div>
            <div className="alert large-alert mb-5">
              <h5 className="font-weight-bold">
                Your personalized plan has been reserved for the next{" "}
                {minutesLasting} minutes!
              </h5>
              <div className="mb-3">
                Save your profile below to claim it now
              </div>
              <div className="font-weight-bold">Time remaining:</div>
              <Timer
                endTime={offerEndTime}
                endCallback={offerEndedTimerCallback}
              ></Timer>
            </div>
            <div className="mb-4">
              <Row>
                <Col xs={{ span: 10, offset: 1 }} md={{ span: 8, offset: 2 }}>
                  <div className="mb-4">
                    <ProgressChart
                      startDate={new Date()}
                      startWeight={startWeight}
                      targetWeight={targetWeight}
                    />
                  </div>
                  <div>
                    <span className="d-block mb-3">
                      Highlights of your customized plan:
                    </span>
                    <ul>
                      <li>Focusing on nutrition</li>
                      <li>With 24/7 Personalized support</li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="mb-5">
              <header className="mb-4 text-center">
                <h4>What you will get</h4>
              </header>
              <div className="mb-4">
                <img
                  src={productPreviewImage}
                  width="100%"
                  className="rounded-corners"
                  alt="Product preview"
                />
              </div>
              <Row>
                <Col md="6">
                  <div className="image-gallery image-gallery--window mb-4">
                    <div className="image-gallery__item">
                      <div className="image-gallery__item-container">
                        <img
                          src={recipeImage1}
                          alt="Wrap with salad"
                          className="image-gallery__item-image"
                        />
                      </div>
                    </div>
                    <div className="image-gallery__item">
                      <div className="image-gallery__item-container">
                        <img
                          src={recipeImage2}
                          alt="Couscous salad"
                          className="image-gallery__item-image"
                        />
                      </div>
                    </div>
                    <div className="image-gallery__item">
                      <div className="image-gallery__item-container">
                        <img
                          src={recipeImage3}
                          alt="Sweet potato recipe"
                          className="image-gallery__item-image"
                        />
                      </div>
                    </div>
                    <div className="image-gallery__item">
                      <div className="image-gallery__item-container">
                        <img
                          src={recipeImage4}
                          alt="Smoothie bowl"
                          className="image-gallery__item-image"
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <ul className="checklist">
                    <li className="checklist__item">
                      Easy-to-follow plans that won't break the bank, developed
                      by professional nutritionists.
                    </li>
                    <li className="checklist__item">
                      500+ delicious recipes providing the fastest healthy
                      change possible.
                    </li>
                    <li className="checklist__item">
                      Customized approach that takes your body type and food
                      preferences into account.
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>

            <div className="mb-5">
              <header className="text-center mb-4">
                <h4>
                  Meet the <span className="text--purple">creator</span> of your
                  <br />
                  personalized plan
                </h4>
              </header>
              <PlanCreator
                name={creator.name || "Tessa"}
                avatarImage={creator.profilePic || avatarImage}
              />
            </div>
            <div className="mb-5">
              <button
                className="btn btn--primary btn--rounded btn--block btn--drop-shadow-soft mb-3"
                disabled={offerEnded || loading}
                onClick={handleContinueButtonClick}
              >
                {(offerEnded && "Your offer has been expired") ||
                  settings.buttonText}
              </button>
              <div className="mb-5 d-flex align-items-center flex-column">
                <span className="d-block mb-3 text--caption">
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="mr-2 text--purple"
                  />
                  {settings.buttonCaption}
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PersonalPlanView;
