import axios from "axios";
import { IP_STACK_ACCESS_KEY } from "../constants";
import getUserLocale from "get-user-locale";
import { getParamByISO } from "iso-country-currency";

export const getClientIPAdress = async () => {
  const backupIP = "212.102.47.84"; // US based
  const response = await axios.get("https://api.ipify.org/?format=json");
  return response.status === 200 ? response.data.ip : backupIP;
};

export const getClientCountryCode = async () => {
  const ipAdress = await getClientIPAdress();
  const response = await axios.get(
    `https://api.ipstack.com/${ipAdress}?access_key=${IP_STACK_ACCESS_KEY}&format=1`
  );
  return response.status === 200 && response.data.country_code
    ? response.data.country_code
    : "US";
};

export const getClientLocale = () => {
  let locale = getUserLocale();
  return locale || "en-US";
};

export const getClientCurrency = (countryCode) => {
  return getParamByISO(countryCode, "currency") || "USD";
};
