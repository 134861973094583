import { useEffect, useState, useContext } from "react";
import { PreferenceContext } from "../context/PreferenceContext";
import { SessionContext } from "../context/SessionContext";
import { getPrice } from "../util/prices";

const usePrices = () => {
  const [preferences] = useContext(PreferenceContext);
  const [session] = useContext(SessionContext);
  const { currency } = preferences;

  // Prices
  const [prices, setPrices] = useState({ setup: 0, total: 0 });

  useEffect(() => {
    if (!session.setupType) return;
    (async () => {
      if (session.setupPrice) {
        const priceObj = await getPrice({
          id: session.setupPrice,
          currency,
          type: session.setupType,
        });
        if (priceObj && priceObj.price)
          setPrices((prevState) => ({ ...prevState, setup: priceObj.price }));
      }
    })();
  }, [currency, session.setupType, session.setupPrice]);

  useEffect(() => {
    if (!session.totalPrice) return;
    (async () => {
      if (session.totalPrice) {
        const priceObj = await getPrice({
          id: session.totalPrice,
          currency,
          type: "subscription",
        });
        if (priceObj && priceObj.price)
          setPrices((prevState) => ({
            ...prevState,
            total: priceObj.price,
          }));
      }
    })();
  }, [currency, session.totalPrice]);

  return prices;
};

export default usePrices;
