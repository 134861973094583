import axios from "axios";
import {
  getClientIdFromStorage,
  getConfigFromStorage,
  getCountryCodeFromStorage,
  getCurrencyFromStorage,
  getLocaleFromStorage,
  getUnitTypeFromStorage,
  getVariantFromStorage,
} from "./storage";

export const storeSessionToDb = async () => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/sessions",
      {
        config: getConfigFromStorage(),
        settings: {
          unitType: getUnitTypeFromStorage(),
          locale: getLocaleFromStorage(),
          countryCode: getCountryCodeFromStorage(),
          currency: getCurrencyFromStorage(),
        },
        metadata: {
          gaClientId: getClientIdFromStorage(),
        },
      }
    );
    if (response.status === 200 && response.data.sessionId)
      return response.data.sessionId;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const updateSessionInDb = async (sessionId, key, value) => {
  if (!sessionId || !key || !value) return;
  try {
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/sessions/update",
      {
        sessionId,
        key,
        value,
      }
    );
    if (response.status === 200 && response.data) return response.data || false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const addSessionEventToDb = async (sessionId, eventName) => {
  if (!sessionId || !eventName) return;
  try {
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/sessions/events",
      {
        sessionId,
        eventName,
      }
    );
    if (response.status === 200 && response.data) return response.data || false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const addSessionMetadataToDb = async (sessionId, key, value) => {
  if (!sessionId || !key || !value) return;
  try {
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/sessions/metadata",
      {
        sessionId,
        key,
        value,
      }
    );
    if (response.status === 200 && response.data) return response.data || false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const getSessionFromDb = async (sessionId) => {
  if (!sessionId) return;
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_URL + "/session",
      { params: { sessionId } }
    );
    if (response.status === 200 && response.data) return response.data || false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const getSessionsFromDb = async () => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_URL + "/sessions"
    );
    if (response.status === 200 && response.data) return response.data || false;
  } catch (err) {
    console.error(err);
    return false;
  }
};
