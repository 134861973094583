import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";

const useFacebookPixel = () => {
  const location = useLocation();

  useEffect(() => {
    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    };
    ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL, {}, options);
  }, []);

  useEffect(() => {
    ReactPixel.pageView();
  }, [location]);
};

export default useFacebookPixel;
