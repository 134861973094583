import { useState, useEffect } from "react";
import axios from "axios";

const useViewData = (slug) => {
  const [creator, setCreator] = useState({
    name: "",
    profilePic: "",
  });

  useEffect(() => {
    (async () => {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "/topic/",
        { params: { topic: slug } }
      );
      if (response.status === 200 && response.data.creator) {
        setCreator(response.data.creator);
      }
    })();
  }, [slug]);

  return creator;
};

export default useViewData;
