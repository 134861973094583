import React from "react";
import { getConfigFromStorage } from "../../util/storage";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import chartsvg from "../../images/chart-1.svg";

const InfoGrahpicView = () => {
  const config = getConfigFromStorage();
  let ageId = (config.age && config.age.age) || 20;
  let genderId = (config.gender && config.gender.gender) || "male";
  let goalId = (config.goal && config.goal.goal) || "loseWeight";
  let ageText = ageId + "s";
  let genderText = genderId === "male" ? "men" : "women";
  let goalText;
  switch (goalId) {
    case "gainMuscle":
      goalText = "gain muscle";
      break;
    case "healthyHabits":
      goalText = "get fit";
      break;
    default:
      goalText = "lose weight";
  }
  return (
    <div className="pt-5 mb-4">
      <header className="d-flex flex-column justify-content-center text-center mb-4">
        <h4>Behavior Change Vs. Restrictive Diet</h4>
        <span>
          We've helped <strong>14,924</strong> {genderText} in their {ageText}{" "}
          to {goalText}
        </span>
      </header>
      <Row>
        <Col xs={{ span: 10, offset: 1 }}>
          <div className="mb-3">
            <img src={chartsvg} alt="Behaviour change chart" width="100%" />
          </div>
        </Col>
      </Row>
      <p className="text-center text--caption">
        Our approach works though habit creation and not restrictive dieting and
        that is why it provides long-lasting results.
      </p>
    </div>
  );
};

export default InfoGrahpicView;
