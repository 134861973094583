import { OFFER_PATH, PROCESSING_PATH } from "../constants";
import structure from "../data/structure";
import gaAnalytics from "../util/ga";

export const Survey = ({ stepPointer, topicSlug, history }) => {
  let phase =
    structure.phases.find((phase) => {
      return phase.steps.find(
        (step) => step.slug === stepPointer || step.id === stepPointer
      );
    }) || structure.phases[0];
  let phaseIndex = structure.phases.findIndex((p) => p.id === phase.id);
  let totalPhases = structure.phases.length || 3;

  let steps = [];
  structure.phases.forEach((phase) => {
    phase.steps.forEach((step) => steps.push(step));
  });

  // Filter on topics
  steps = steps.filter((step) => {
    let enabled = true;
    if (topicSlug && step.disabledTopics && step.disabledTopics.length > 0)
      enabled = !step.disabledTopics.includes(topicSlug);

    if (step.enabledTopics && step.enabledTopics.length > 0)
      enabled = (topicSlug && step.enabledTopics.includes(topicSlug)) || false;
    return enabled;
  });

  let step =
    steps.find(
      (step) => step.slug === stepPointer || step.id === stepPointer
    ) || steps[0];
  let stepId = step.id;
  let stepIndex = steps.findIndex((step) => step.id === stepId) || 0;

  // Filter step options on topics
  if (topicSlug && step.fields)
    step.fields = step.fields.map((field) => {
      if (field.options)
        field.options = field.options.filter((option) => {
          if (
            option.disabledTopics &&
            option.disabledTopics.includes(topicSlug)
          )
            return false;
          return true;
        });
      return field;
    });

  let phaseStepIndex = phase.steps.findIndex((step) => step.id === stepId) || 0;
  let phaseStepProgress = (phaseStepIndex / phase.steps.length) * 100;

  let showSubmitButton =
    (step.fields &&
      step.fields.some((field) =>
        ["multipleChoice", "weightField", "heightField"].includes(field.type)
      )) ||
    step.showSubmitButton;

  let showUnitSwitcher =
    step.fields &&
    step.fields.some((field) =>
      ["weightField", "heightField"].includes(field.type)
    );

  let isFirstStep = stepIndex === 0;

  const navigateToFirstStep = () => {
    navigateToStep(0);
  };

  const navigateToStep = (index) => {
    let changedPath;
    let changedStepSlug = steps[index] && steps[index].slug;
    let topicPath = topicSlug ? `/topic/${topicSlug}` : "";
    if (!changedStepSlug) {
      let stepPath = steps[0].slug;
      changedPath = index > stepIndex ? OFFER_PATH : `/step/${stepPath}/`;
    } else {
      changedPath = `/step/${changedStepSlug}/`;
    }
    changedPath = topicPath + changedPath;
    let navigatingForward = index > stepIndex;
    if (step.loaderAfter && navigatingForward) {
      localStorage.setItem("redirectAfter", changedPath);
      history.push(`${topicPath}${PROCESSING_PATH}`);
    } else {
      history.push(changedPath);
    }
  };

  const navigateToNextStep = () => {
    gaAnalytics.sendEvent({
      category: "Wizard",
      action: "Click",
      label: "Complete step",
      value: stepIndex + 1,
    });
    navigateToStep(stepIndex + 1);
  };

  const navigateToPreviousStep = () => {
    navigateToStep(stepIndex - 1);
  };

  return {
    steps,
    step,
    stepIndex,
    isFirstStep,
    phase,
    phaseIndex,
    phaseStepProgress,
    totalPhases,
    navigateToStep,
    navigateToNextStep,
    navigateToPreviousStep,
    showSubmitButton,
    showUnitSwitcher,
    navigateToFirstStep,
  };
};
